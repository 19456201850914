import React, { useState, useEffect } from 'react';
import { Card, Typography, Switch, Modal, Tooltip } from 'antd';
import { SettingOutlined, EditOutlined, UploadOutlined, InfoCircleOutlined, CrownOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './cardstyle.css';
import LottieAnimation from './LottieAnimation';
import SettingsPage2 from './SettingsPage2';
import TranslationSettingsPage from './Settingstranslation';
import Etrade from './etrade';
import Mobile from './mobile';
import Landingpages from './Landingpages';
import AISalesPersonalisation from './AIsalespersonalisation';
import AILandingPageOptimization from './AILandingPageOptimization';
import { sendFeatureStatusToServer2, fetchSettingsFromServer } from './utils.js';

const { Text } = Typography;

const URLDataCard = ({ url, onSwitchToVariantEdit }) => {
    const defaultSettings = {
        AiABTestingEnabled: false,
        AiBasicTranslationEnabled: true,
        AdvancedTranslationEnabled: false,
        AiMobileAdaptationEnabled: false,
        ProductDescOptEnabled: false,
        LandingPagesEnabled: false,
        SmartGdprEnabled: false,
    };

    const [settings, setSettings] = useState(defaultSettings);
    const [currentModal, setCurrentModal] = useState({ visible: false, type: null, context: {} });

    const handleSettingChange = (key, value) => {
        const updatedSettings = { ...settings, [key]: value };
        setSettings(updatedSettings);

        sendFeatureStatusToServer2({
            url: url,
            data: updatedSettings
        }).catch(error => console.error(error));
    };

    useEffect(() => {
        async function loadSettings() {
            try {
                const serverSettings = await fetchSettingsFromServer(url);
                if (serverSettings) {
                    setSettings({ ...defaultSettings, ...serverSettings });
                }
            } catch (error) {
                console.error('Failed to fetch settings:', error);
            }
        }
        loadSettings();
    }, [url]);

    const showModal = (type, context = {}) => {
        setCurrentModal({ visible: true, type, context });
    };

    const closeModal = () => {
        setCurrentModal(prev => ({ ...prev, visible: false }));
    };

    return (
        <Card bordered={false} className="no-shadow-card" style={{ width: '100%' }}>
            <div className="bottom-section">
                <div className="div-2">
                    {/* General AI Model Card */}
                    <div className="column ai-container">
                        <div className="ai-choice-container">
                            <div className="div-4" style={{ height: '350px' }}>
                                <div className="ai-card-content">
                                    <div className="lottie-container">
                                        <LottieAnimation 
                                          
            animationPath="https://lottie.host/d2fe082a-11e9-4cc7-bdcc-d7fec198dae1/rAp5IJKdyh.json" 
            width={300} 
            height={300} 
          />
        </div>
        <div className="ai-text-content" data-seatextdont>
          <div className="ai-title-container" data-seatextdont>
            <h2 className="ai-title">General AI Model: improves any website sales and conversion rates</h2>
          </div>
          <div className="ai-description">
  SEATEXT AI boosts your sales by gradually generating over 1,000 text variants (10-15 variants per batch) for your website, just like a professional copywriter. It then automatically A/B tests these variants, just like an expert online marketer, identifying which versions actually increase your conversion rate and sales.

  This AI model works with any website and can access up to 90% of the text on your pages. You can easily control which text the AI fine-tunes in the settings. The model follows Amazon's famous approach to A/B testing, where Amazon conducts 12,000 A/B tests each year. This model works in any language your website is written in.
</div>
          <div className="ai-card-actions">
            <Switch 
              checked={settings.AiABTestingEnabled} 
              onChange={(checked) => handleSettingChange('AiABTestingEnabled', checked)} 
            />
            <div className="ai-setting-button" 
                 onClick={() => showModal('AIABTesting', {url, aiName: ''})}>
              <SettingOutlined /> Settings
            </div>
            <div className="ai-variant-button" 
                 onClick={onSwitchToVariantEdit}>
              <EditOutlined /> AI Variants
            </div>

            <a 
        href="https://seatext.com/seatext-general-ai-model" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>






          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<div className="column ai-container">
    <div className="ai-choice-container">
        <div className="div-4" style={{ height: '350px' }}>
            <div className="ai-card-content">
                <div className="lottie-container">
                    <LottieAnimation
                        animationPath="https://lottie.host/f1888009-497f-4f39-957c-2dee57224074/cIuSvSj7bw.json"
                        width={400}
                        height={400}
                    />
                </div>
                <div className="ai-text-content">
                    <div className="ai-title-container">
                        <h2 className="ai-title">Online-store AI product descriptions text optimisation</h2>
                    </div>
                    <div className="ai-description">
  The core feature of this AI is boosting online store sales by optimizing product descriptions. It automatically generates and A/B tests multiple versions, identifying the most compelling language to drive purchases, leading to sales increases of up to 40%. Its unique strength lies in its specialized Large Language Model, trained specifically on high-converting e-commerce content, allowing it to craft product descriptions that outperform those created by general AI models. 

  The new product descriptions can be up to 30% longer than those you currently use, so you may need to adjust the design of your pages, especially by making text areas more flexible.
</div>
               
                    <div className="ai-card-actions">
                        <Switch checked={settings.ProductDescOptEnabled} onChange={(checked) => handleSettingChange('ProductDescOptEnabled', checked)} />
                        <div className="ai-setting-button"
                            onClick={() => showModal('Product Description Optimization', {url, aiName: 'Product Description Optimization'})}
                        >
                            <SettingOutlined /> Settings
                        </div>
                        <div className="ai-variant-button"
                            onClick={onSwitchToVariantEdit}
                        >
                            <EditOutlined /> AI Variants
                        </div>

                        <a 
        href="https://seatext.com/improve-your-e-stores-product-descriptions-with-ai" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>





                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div className="div-3">
        <div className="column ai-container">
            <div className="ai-choice-container">
                <div className="div-4" style={{ height: '350px' }}>
                    <div className="ai-card-content">
                        <div className="lottie-container" style={{ transform: 'scale(0.8)', marginTop: '-20px' }}>
                            <LottieAnimation
                                animationPath="https://lottie.host/f341c635-43aa-44fb-bdcb-978f40cb8e52/aSyfdQjNP1.json"
                                width={300}
                                height={300}
                            />
                        </div>
                        <div className="ai-text-content">
                            <div className="ai-title-container">
                                <h2 className="ai-title">Smart GDPR/CCPA cookies</h2>
                            </div>
                            <div className="ai-description">
    We've created an adaptive, conversion-friendly cookie popup. Initially, it appears as a minimal, non-intrusive banner that stays small for the first 20 seconds, reducing bounce rates and allowing visitors to see content immediately. After 20 seconds, it expands to a standard size, encouraging visitors to give consent—perfect for retargeting. This approach avoids the typical conversion drop seen with traditional cookie popups that block content right away. Thanks to smart geo-targeting and consent history analysis, many users won’t see the popup at all. Fully GDPR/CCPA compliant, it’s free forever and requires no setup changes. It’s compatible with most analytics platforms, like Google Analytics and Microsoft Clarity. For advanced options, integrate with Seatext’s privacy settings for granular cookie customization. This setup is easy and takes only a few minutes to implement, letting you retain your current GDPR form while updating only the widget.
</div>
                            <div className="ai-card-actions">
                                <Switch 
                                    checked={settings.SmartGdprEnabled} 
                                    onChange={(checked) => handleSettingChange('SmartGdprEnabled', checked)} 
                                />
                                  <a 
        href="https://seatext.com/cookie-consent-solution-seatext" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>



























<div className="div-3">
    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/a0a3892d-0214-476f-9534-ca715b1a9f8a/uFmaS5zzFj.json"
                            width={400}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">AI Basic translation (without AB testing)</h2>
                        </div>
                        <div className="ai-description">
                        Boost your sales with a multilingual website offering basic translations in 125 languages. This AI is perfect for sites with a small number of international visitors and no need for A/B testing of translations. Simply activate the model, and it will handle the rest. You can still use other website translators like Weglot or Google Translate, as this AI is designed to work alongside them without interference.
                        </div>
                        <div className="ai-card-actions">
                            <Switch checked={settings.AiBasicTranslationEnabled} onChange={(checked) => handleSettingChange('AiBasicTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>


                            <Link 
        to="/welcome/image-upload" 
        className="ai-setting-button"
        style={{ marginLeft: '8px' }}
    >
        <UploadOutlined /> Upload Translated Images
    </Link>


    <a 
        href="https://seatext.com/translation" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                <div className="premium-badge">
                    <CrownOutlined /> Premium Model
                </div>
            </Tooltip>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/8eb52a8d-3298-466f-9b2b-c62f0e04e193/LaIwtdYRte.json"
                            width={350}
                            height={180}
                        />
                    </div>
                    <div className="ai-text-content">
                        <div className="ai-title-container">
                            <h2 className="ai-title">Advanced translation with A/B testing</h2>
                        </div>
                        <div className="ai-description">
                        This model introduces a new AI-driven approach to ensure the best, error-free translations for websites. Instead of one translation, it generates up to 10 variants per language and A/B tests them with real users to identify the most accurate, high-performing options. This continuous optimization makes it ideal for sites with over 1,000 international visitors daily, driving a significant increase in international sales—typically around +25%—by ensuring translations are flawless and user-friendly.</div>
                   
                        <div className="ai-card-actions">
                            <Switch checked={settings.AdvancedTranslationEnabled} onChange={(checked) => handleSettingChange('AdvancedTranslationEnabled', checked)} />
                            <div className="ai-setting-button"
                                onClick={() => showModal('Translation', {url, aiName: 'Advanced translation with A/B testing'})}
                            >
                                <SettingOutlined /> Settings
                            </div>
                            <div className="ai-variant-button"
                                onClick={onSwitchToVariantEdit}
                            >
                                <EditOutlined /> AI Variants
                            </div>


                            
                            <Link 
        to="/welcome/image-upload" 
        className="ai-setting-button"
        style={{ marginLeft: '8px' }}
    >
        <UploadOutlined /> Upload Translated images
    </Link>


    <a 
        href="https://seatext.com/translation" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="div-33">
    <div className="column ai-container">
      <div className="ai-choice-container">
        <div className="div-4" style={{ height: '350px' }}>
          <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
            <div className="premium-badge">
              <CrownOutlined /> Premium Model
            </div>
          </Tooltip>
          <div className="ai-card-content">
            <div className="lottie-container">
              <LottieAnimation
                animationPath="https://lottie.host/a57fc31f-6207-4375-859c-22347151cdab/Cd2HOPFeC3.json"
                width={200}
                height={200}
              />
            </div>
            <div className="ai-text-content">
              <div className="ai-title-container">
                <h2 className="ai-title">Advanced AI model for mobile adaptation</h2>
              </div>
              <div className="ai-description">
                This AI model boosts mobile conversions by automatically optimizing website content for smaller screens. It transforms desktop content into shorter, more impactful mobile versions, ensuring easy readability and engagement. The process also includes automated A/B testing to find the best-performing version, further increasing conversions. Since 90% of websites use the same content for both mobile and desktop, making mobile sites harder to read, this model trims text where possible and shows optimized versions to mobile users automatically.
              </div>
              
              <div className="ai-card-actions">
                <div className="flex items-center gap-2">
                  <Switch 
                    disabled
                    checked={false}
                    className="opacity-50 cursor-not-allowed"
                  />
                  <span className="text-sm text-gray-500 flex items-center gap-1">
                    <LockOutlined /> Closed Beta Only
                  </span>
                </div>
                <div className="ai-setting-button opacity-50 cursor-not-allowed">
                  <SettingOutlined /> Settings
                </div>
                <div className="ai-variant-button opacity-50 cursor-not-allowed">
                  <EditOutlined /> AI Variants
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="column ai-container">
        <div className="ai-choice-container">
            <div className="div-4" style={{ height: '350px' }}>
            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                <div className="premium-badge">
                    <CrownOutlined /> Premium Model
                </div>
            </Tooltip>
                <div className="ai-card-content">
                    <div className="lottie-container">
                        <LottieAnimation
                            animationPath="https://lottie.host/1006dbdf-c308-47fb-b65c-60aca81ad2b2/u9QlVhfHpo.json"
                            width={200}
                            height={200}
                        />
                    </div>
                    <div className="ai-text-content">
    <div className="ai-title-container">
        <h2 className="ai-title">AI personalizes your website based on data from Clay.com/CRM/Emails</h2>
    </div>
    <div className="ai-description">
    Transform your website instantly with AI-driven personalization powered by your existing data from Clay.com, CRM, and email interactions. Our solution automatically creates tailored experiences that speak directly to your prospects' pain points, dramatically improving engagement and conversion rates in your ABM campaigns. Generate personalized website experiences in seconds - each visitor gets a unique URL showcasing content perfectly aligned with their business needs. Drive higher engagement with content that resonates.
    </div>
 
    <div className="ai-card-actions">
        <Switch checked={settings.LandingPagesEnabled} onChange={(checked) => handleSettingChange('LandingpagesEnabled', checked)} />
        <div className="ai-setting-button" onClick={() => showModal('AISalesPersonalisation', {url, aiName: 'AISalesPersonalisation'})}>
            <SettingOutlined /> Settings
        </div>


        <a 
        href="https://seatext.com/seatext-ai-personalization" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="ai-setting-button"
    >
        <InfoCircleOutlined /> Learn More
    </a>
                           
                        </div>
                    </div>
                </div>






            </div>
        </div>
    </div>


    <div className="column ai-container">
                    <div className="ai-choice-container">
                        <div className="div-4" style={{ height: '350px' }}>
                            <Tooltip title="You can use this model only if you are on a paid plan or have a positive balance">
                                <div className="premium-badge">
                                    <CrownOutlined /> Premium Model
                                </div>
                            </Tooltip>
                            <div className="ai-card-content">
                                <div className="lottie-container">
                                    <LottieAnimation
                                        animationPath="https://login.seatext.com/google.json"
                                        width={200}
                                        height={300}
                                    />
                                </div>
                                <div className="ai-text-content">
                                    <div className="ai-title-container">
                                        <h2 className="ai-title">AI Optimize Landing Pages for Google Ads Keywords</h2>
                                    </div>
                                    <div className="ai-description">
                                        Improve your Google Ads performance by 20% with our intelligent landing page optimization AI. This advanced system automatically generates keyword-specific versions of your landing pages, ensuring perfect alignment between search intent and content. For each keyword in your campaign, our AI crafts a uniquely optimized page version that speaks directly to what your visitors are searching for. This precise matching of search terms to landing page content typically drives a 20% increase in conversion rates. By creating thousands of keyword-optimized landing page variants, each with its own dedicated URL, you can scale your Google Ads campaigns while maintaining high relevance and engagement. The system automatically handles content adaptation, ensuring each version maintains your brand voice while specifically addressing the searcher's intent, dramatically improving your quality scores and conversion rates.
                                    </div>
                                    <div className="ai-card-actions">
                                        <Switch checked={settings.LandingPagesEnabled} onChange={(checked) => handleSettingChange('LandingPagesEnabled', checked)} />
                                        <div className="ai-setting-button" onClick={() => showModal('AILandingPageOptimization', {url, aiName: 'AILandingPageOptimization'})}>
                                            <SettingOutlined /> Settings
                                        </div>
                                        <a 
                                            href="https://seatext.com/seatext-ai-personalization" 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            className="ai-setting-button"
                                        >
                                            <InfoCircleOutlined /> Learn More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

















</div>
</div>

<Modal
    title={`${currentModal.context.aiName} Settings`}
    visible={currentModal.visible}
    onCancel={closeModal}
    footer={null}
    width="66%"
    style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        width: '66%',
        margin: 0,
        padding: 0,
    }}
    bodyStyle={{ 
        height: '100%', 
        overflowY: 'auto',
        width: '100%'
    }}
    wrapClassName="right-side-modal"
    maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
>
    <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
        {currentModal.type === 'Product Description Optimization' && <Etrade context={currentModal.context} />}
        {currentModal.type === 'AIABTesting' && <SettingsPage2 context={currentModal.context} />}
        {currentModal.type === 'Translation' && <TranslationSettingsPage context={currentModal.context} />}
        {currentModal.type === 'Mobile' && <Mobile context={currentModal.context} />}
        {currentModal.type === 'Landingpages' && <Landingpages context={currentModal.context} />}
        {currentModal.type === 'AISalesPersonalisation' && <AISalesPersonalisation context={currentModal.context} />}
        {currentModal.type === 'AILandingPageOptimization' && <AILandingPageOptimization context={currentModal.context} />}
    </div>
</Modal>
        </Card>
    );
};

export default URLDataCard;