import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Switch, Transfer, message, Form, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2, fetchSettingsFromServer, getPageListFromSettings } from './utils';
import LottieAnimation from './LottieAnimation';
import VideoIntro from './VideoIntro'; // Import the new VideoIntro component

const { Title, Text } = Typography;
const { Option } = Select;



 

// Initialize an array of 50+ languages
const allLanguages = [
  "Afrikaans (South Africa)",
  "Albanian (Albania)",
  "Amharic (Ethiopia)",
  "Arabic (Saudi Arabia)",
  "Armenian (Armenia)",
  "Assamese (India)",
  "Azerbaijani (Azerbaijan)",
  "Basque (Spain)",
  "Belarusian",
  "Bengali (India)",
  "Bhojpuri",
  "Bosnian (Bosnia and Herzegovina)",
  "Breton",
  "Bulgarian (Bulgaria)",
  "Burmese (Myanmar)",
  "Catalan (Spain)",
  "Cebuano (Philipines)",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Croatian (Croatia)",
  "Czech (Czech Republic)",
  "Danish (Denmark)",
  "Dogri",
  "Dutch (Netherlands)",
  "English",

  "Estonian (Estonia)",
  "Faroese",
  "Filipino",
  "Filipino (Tagalog)",
  "Finnish (Finland)",
  "French",
  "Frisian",
  "Galician (Spain)",
  "Georgian (Georgia)",
  "German (Austria)",
  "German (Germany)",
  "Greek (Greece)",
  "Greenlandic",
  "Guarani",
  "Gujarati (India)",
  "Hausa (Nigeria)",
  "Hebrew (Israel)",
  "Hindi (India)",
  "Hungarian (Hungary)",
  "Icelandic (Iceland)",
  "Igbo (Nigeria)",
  "Indonesian (Indonesia)",
  "Irish (Ireland)",
  "Italian (Italy)",
  "Japanese (Japan)",
  "Kannada (India)",
  "Kashmiri",
  "Kazakh (Kazakhstan)",
  "Khmer (Cambodia)",
  "Kinyarwanda (Rwanda)",
  "Kongo (Congo)",
  "Konkani",
  "Korean (South Korea)",
  "Kurdish (Turkey)",
  "Kyrgyz (Kyrgyzstan)",
  "Lao (Laos)",
  "Latvian (Latvia)",
  "Lingala (Congo)",
  "Lithuanian (Lithuania)",
  "Malay (Malaysia)",
  "Malayalam (India)",
  "Maltese (Malta)",
  "Manipuri",
  "Maithili",
  "Mongolian (Mongolia)",
  "Nepali (India)",
  "Nepali (Nepal)",
  "Norwegian (Norway)",
  "Oromo (Ethiopia)",
  "Pashto (Afghanistan)",
  "Persian (Iran)",
  "Polish (Poland)",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi (India)",
  "Punjabi (Pakistan)",
  "Romanian (Romania)",
  "Russian (Russia)",
  "Sanskrit",
  "Scottish Gaelic",
  "Serbian (Serbia)",
  "Shona (Zimbabwe)",
  "Sindhi (India)",
  "Sindhi (Pakistan)",
  "Sinhala (Sri Lanka)",
  "Slovak (Slovakia)",
  "Slovenian (Slovenia)",
  "Somali (Somalia)",
  "Southern Sotho (South Africa)",
  "Spanish (Mexico)",
  "Spanish (Spain)",
  "Swahili (Kenya)",
  "Swati (South Africa)",
  "Swedish (Sweden)",
  "Tajik (Tajikistan)",
  "Tamil (India)",
  "Tatar",
  "Telugu (India)",
  "Thai (Thailand)",
  "Tigrinya (Ethiopia)",
  "Tsonga (South Africa)",
  "Turkish (Turkey)",
  "Turkmen (Turkmenistan)",
  "Tswana (Botswana)",
  "Tswana (South Africa)",
  "Ukrainian (Ukraine)",
  "Urdu (India)",
  "Urdu (Pakistan)",
  "Uyghur",
  "Uzbek (Uzbekistan)",
  "Vietnamese (Vietnam)",
  "Volapük",
  "Walloon",
  "Welsh (United Kingdom)",
  "Xhosa (South Africa)",
  "Yiddish",
  "Yoruba (Nigeria)",
  "Zulu (South Africa)",
  "Taiwanese Hokkien",
  "Taiwanese Hakka"
  ].map(lang => ({ key: lang, title: lang }));
  const featureNameToDBField = {
    "AiBasicTranslationEnabled": "AiBasicTranslationEnabled",
    "BasicTranslationFontAdjustmentEnabled": "BasicTranslationFontAdjustmentEnabled",
    "AdvancedTranslationEnabled": "AdvancedTranslationEnabled",
    "AdvancedTranslationCreativityLevel": "AdvancedTranslationCreativityLevel",
    "AdvancedTranslationVariantCount": "AdvancedTranslationVariantCount",
    "AdvancedTranslationConfidenceLevel": "AdvancedTranslationConfidenceLevel",
    "AdvancedTranslationBrandVoicePreservation": "AdvancedTranslationBrandVoicePreservation",
    "TranslationAIautoapproval":"TranslationAIautoapproval"
  };
  
  const sliderStyle = (isActive) => ({
    filter: isActive ? 'none' : 'grayscale(100%)',
    opacity: isActive ? 1 : 0.5,
  });
  
  function TranslationSettingsPage(props) {
    const defaultSettings = {
      AiBasicTranslationEnabled: false,
      AdvancedTranslationEnabled: false,
      DisallowedLanguagesForTranslation: [],
      AdvancedTranslationCreativityLevel: 3,
      AdvancedTranslationVariantCount: 5,
      AdvancedTranslationConfidenceLevel: 85,
      AdvancedTranslationBrandVoicePreservation: 5,
      BasicTranslationFontAdjustmentEnabled: true,
      TranslationAIautoapproval: true, // Add this line
    };
  
    const [settings, setSettings] = useState(defaultSettings);
    const [urls, setUrls] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState('');
    const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);
  
    useEffect(() => {
      let initialUrl = props.context?.url || '';
      let isUrlFromCookies = false;
  
      if (!initialUrl) {
        const domainName = Cookies.get('domainName');
        if (domainName) {
          initialUrl = `${domainName} Whole website`;
          isUrlFromCookies = true;
        }
      }
  
      setSelectedUrl(initialUrl);
  
      const fetchPageList = async () => {
        let pageList = await getPageListFromSettings();
        if (pageList && pageList.length > 0) {
          pageList.sort((a, b) => {
            if (a.includes("Whole website")) return -1;
            if (b.includes("Whole website")) return 1;
            return a.length - b.length;
          });
  
          const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
          if (wholeWebsiteUrls.length > 1) {
            const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
            pageList = pageList.filter(url => !url.includes("Whole website"));
            pageList.unshift(firstWholeWebsiteUrl);
          }
  
          setUrls(pageList);
          setSelectedUrl(initialUrl || pageList[0]);
          setShowWholeWebsiteWarning(!!pageList.find(url => url.includes("Whole website")));
        }
      };
  
      fetchPageList();
      loadSettings(initialUrl);
  
      return () => {
        if (isUrlFromCookies) {
          // Clean up if needed
        }
      };
    }, [props.context?.url]);
  
    const handleSettingChange = (key, value) => {
      let updatedSettings = { ...settings };
  
      if (key === 'DisallowedLanguagesForTranslation') {
        updatedSettings.DisallowedLanguagesForTranslation = value;
      } else {
        updatedSettings[key] = value;
      }
  
      setSettings(updatedSettings);
  
      const payload = {
        url: selectedUrl,
        data: updatedSettings
      };
  
      sendFeatureStatusToServer2(payload).catch(error => {
        console.error(error);
        message.error('Failed to update settings.');
      });
    };
  
    function convertServerSettingsToClient(serverSettings) {
      let clientSettings = {};
  
      Object.entries(featureNameToDBField).forEach(([clientKey, serverKey]) => {
        if (serverSettings.hasOwnProperty(serverKey) && serverSettings[serverKey] !== undefined) {
          clientSettings[clientKey] = serverSettings[serverKey];
        }
      });
  
      return clientSettings;
    }
  
    const loadSettings = async (url) => {
      try {
        const serverSettings = await fetchSettingsFromServer(url);
        if (serverSettings) {
          const clientSettings = convertServerSettingsToClient(serverSettings);
          const DisallowedLanguages = serverSettings.DisallowedLanguagesForTranslation || [];
          setSettings({ ...defaultSettings, ...clientSettings, DisallowedLanguagesForTranslation: DisallowedLanguages });
        } else {
          setSettings(defaultSettings);
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
        setSettings(defaultSettings);
      }
    };
  
    const handleUrlChange = (value) => {
      setSelectedUrl(value);
      setShowWholeWebsiteWarning(value.includes("Whole website"));
      loadSettings(value);
    };
  
    const renderTitle = () => {
      const titleBase = "AI Website Translation Settings";
      return selectedUrl ? `${titleBase} for ${selectedUrl}` : titleBase;
    };


    
  
   
      return (
        <div data-seatextdont  style={{ display: 'flex', justifyContent: 'center', maxWidth: '100%' }} >
          <VideoIntro videoUrl="https://login.seatext.com/Translation-video.mp4" />
          <div style={{ width: '100%', margin: '0 auto' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {/* AI Basic Translation Card */}
              <div style={{ border: '1px solid #e8e8e8', padding: '20px', marginBottom: '20px', backgroundColor: '#f8f8f8' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px' }}>
                  <LottieAnimation
                    animationPath="https://lottie.host/a0a3892d-0214-476f-9534-ca715b1a9f8a/uFmaS5zzFj.json"
                    width={300}
                    height={200}
                  />
                  <div style={{ marginLeft: '30px', width: '70%' }}>

                  <Text align="left" style={{ fontSize: '24px', fontWeight: 500,   fontFamily: 'Roboto, Arial, sans-serif' }}>AI Basic Website Translation </Text>
                    
                    <div style={{ 
                      fontSize: '18px', 
                      color: 'rgba(0,0,0,0.65)', 
                      marginTop: '10px'
                      , maxWidth: '90%'
                    }}>
                      Enhance your global reach with our AI Basic Translation. This tool provides quick and efficient translations in 125 languages, perfect for websites looking to expand their international presence without the need for advanced testing.
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                      <Switch
                        checked={settings.AiBasicTranslationEnabled}
                        onChange={(checked) => handleSettingChange('AiBasicTranslationEnabled', checked)}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                      />
                      <span style={{ marginLeft: '10px', fontSize: '18px', color: 'rgba(0,0,0,0.65)' }}>
                        Activate AI Basic Translation / Deactivate AI Basic Translation
                      </span>
                    </div>
                  </div>
                </div>
              </div>
    
              {/* Advanced Translation with A/B Testing Card */}
              <div style={{ border: '1px solid #e8e8e8', padding: '20px', marginBottom: '20px', backgroundColor: '#f8f8f8' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px' }}>
                  <LottieAnimation
                    animationPath="https://lottie.host/8eb52a8d-3298-466f-9b2b-c62f0e04e193/LaIwtdYRte.json"
                   width={370}
                    height={200}
                  />
                  <div style={{ marginLeft: '30px', width: '70%' }}>
                  <Text align="left" style={{ fontSize: '24px', fontWeight: 500,   fontFamily: 'Roboto, Arial, sans-serif' }}>Advanced AI Website Translation with A/B Testing </Text>
               
                    <div style={{ 
                      fontSize: '18px', 
                      color: 'rgba(0,0,0,0.65)', 
                      marginTop: '10px'
                       , maxWidth: '90%'
                    }}>
                      This sophisticated system not only translates your content into 125 languages, but also creates up to 10 different translations for each of those languages. It then employs A/B testing to identify which translation variant has no errors, sounds better, and has a higher conversion rate.
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                      <Switch
                        checked={settings.AdvancedTranslationEnabled}
                        onChange={(checked) => handleSettingChange('AdvancedTranslationEnabled', checked)}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                      />
                      <span style={{ marginLeft: '10px', fontSize: '18px', color: 'rgba(0,0,0,0.65)' }}>
                        Activate Advanced Translation / Deactivate Advanced Translation
                      </span>
                    </div>
                    
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                      <img
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6ee974d01af2b851c664f3820c487ef8bcb9b56f1e93895d3fc10c00bca4c67?"
                        alt="A/B testing icon"
                        style={{ width: '24px', height: '24px', marginRight: '10px' }}
                      />
                      <span style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
                        increase conversion rate by 25% and eliminate translation errors
                      </span>
                    </div>
                  </div>
                </div>
              </div>

<Form layout="vertical" style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}>
  <Select
    showSearch
    style={{ width: '100%' }}
    placeholder="Select a URL for translation"
    value={selectedUrl}
    onChange={handleUrlChange}
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {urls.map(url => (
      <Option value={url} key={url}>{url}</Option>
    ))}
  </Select>
</Form>

{showWholeWebsiteWarning && (
  <div style={{ margin: '10px 0', padding: '10px', backgroundColor: '#f8f8f8', border: '1px solid #e8e8e8' }}>
    You are editing settings for the entire website. Changes made here will affect all pages on your website,
    including those added later. If you want to change settings for a particular URL and have specific settings for it,
    please select a URL from the dropdown list above first.
  </div>
)}
 </div>




 <div style={{ width: '100%', maxWidth: '1900px', margin: 'auto' }}>
        <div style={{ width: '100%' }}>
        { (
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'stretch' }}>
              <Col span={14}>
                <Card 
                  style={{ 
                    height: '100%', 
                    backgroundColor: '#f8f8f8', 
                    border: '1px solid #d9d9d9'
                  }}
                >
                  <Text>Which languages are allowed for translation and which are Disallowed.</Text>
                  <Text strong style={{ display: 'block', marginTop: '10px', marginBottom: '10px' }}>
                    You can let the AI work with all 125 languages. It will only translate your site when there is sufficient demand for another language, ensuring that translations are genuinely required.
                  </Text>
                  <Transfer
                    dataSource={allLanguages}
                    titles={['Allowed Languages', 'Disallowed Languages']}
                    targetKeys={settings.DisallowedLanguagesForTranslation}
                    onChange={(nextTargetKeys) => handleSettingChange('DisallowedLanguagesForTranslation', nextTargetKeys)}
                    render={item => item.title}
                    
                    style={{ marginTop: '10px' }}
                    listStyle={{
                      width: 300,
                      height: 300,
                    }}
                  />
                </Card>
              </Col>
              <Col span={10}>
                <Card 
                  style={{ 
                    height: '100%', 
                    backgroundColor: '#f8f8f8', 
                    border: '1px solid #d9d9d9'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Switch
                      checked={settings.BasicTranslationFontAdjustmentEnabled}
                      onChange={(checked) => handleSettingChange('BasicTranslationFontAdjustmentEnabled', checked)}
                    />
                    <Text style={{ marginLeft: '10px' }}>
                      Allow AI to adjust fonts for languages that might not display well with your website's default fonts
                    </Text>
                    
                  </div>
                  
                  <Text type="secondary">
                    (e.g., Chinese, Japanese, Korean, Thai, Hindi, and more)
                  </Text>
                  <img src="https://login.seatext.com/3.jpg" alt="SEA Text Tags" style={{ width: '380px', marginRight: '40px' }} />
                </Card>
              </Col>
            </Row>
          )}
           
           <Card 
              style={{ 
                backgroundColor: '#f8f8f8', 
                border: '1px solid #d9d9d9',
                marginBottom: '20px'
              }}
            >
              <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                <Switch
                  checked={settings.TranslationAIautoapproval}
                  onChange={(checked) => handleSettingChange('TranslationAIautoapproval', checked)}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  style={{ width: '55px' }}
                />
                <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>Automatically approve AI trasnlations for all languages.</span>
                  <span style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
    Turn off if you prefer to manually review translations before they are shown to your visitors. 
    WARNING! This can be time-consuming, as you will need to manually review each AI variant on every page, which may take hours for some clients.
</span></div>
              </div>
              </Card>

    
              <div style={sliderStyle(settings.AdvancedTranslationEnabled)}>
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>AI Creativity Level:</Text>
                    <Slider
                      min={1}
                      max={5}
                      step={1}
                      marks={{
                        1: 'Max',
                        2: 'High',
                        3: 'Medium',
                        4: 'Low',
                        5: 'Minimal Changes',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationCreativityLevel', value)}
                      value={settings.AdvancedTranslationCreativityLevel}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      Adjust how creative the AI should be creating additional variants for translation. A Max setting means the AI will generate content that's significantly different from the original, while a lower setting limits creativity to minimal changes.
                    </Text>
                  </Col>
                </Row>
    
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>Number of Variants to Create:</Text>
                    <Slider
                      min={2}
                      max={8}
                      step={1}
                      marks={{
                        3: '3 Variants',
                        4: '4 Variants',
                        6: '6 Variants',
                        8: '8 Variants',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationVariantCount', value)}
                      value={settings.AdvancedTranslationVariantCount}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                    </Text>
                  </Col>
                </Row>
    
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>Preserve Your Brand Voice (0-10):</Text>
                    <Slider
                      min={0}
                      max={10}
                      step={1}
                      marks={{
                        0: '0',
                        2: '2',
                        4: '4',
                        5: '5',
                        6: '6',
                        8: '8',
                        10: '10',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationBrandVoicePreservation', value)}
                      value={settings.AdvancedTranslationBrandVoicePreservation}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      Should AI copy your brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                    </Text>
                  </Col>
                </Row>
    
                <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
                  <Col span={12}>
                    <Text>Confidence Level for A/B Testing:</Text>
                    <Slider
                      min={70}
                      max={95}
                      step={5}
                      marks={{
                        70: '70%',
                        75: '75%',
                        80: '80%',
                        85: '85% Recommended',
                        90: '90%',
                        95: '95%',
                      }}
                      onChange={(value) => handleSettingChange('AdvancedTranslationConfidenceLevel', value)}
                      value={settings.AdvancedTranslationConfidenceLevel}
                      disabled={!settings.AdvancedTranslationEnabled}
                    />
                  </Col>
                  <Col span={12} style={{ paddingLeft: '50px' }}>
                    <Text>
                      This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                    </Text>
                  </Col>
                </Row>
              </div>
            
          </div>
        </div>
      </div>
      </div>
      
      
      
    );
                    }
export default TranslationSettingsPage;
