import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Switch, Select, Form, message, Alert } from 'antd';
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2, fetchSettingsFromServer, getPageListFromSettings } from './utils';
import LottieAnimation from './LottieAnimation';
import "./style.css";

const { Title, Text } = Typography;
const { Option } = Select;

function Etrade(props) {
  const defaultSettings = {
    ProductDescOptEnabled: true,
    ProductNameCustomizationEnabled: true,
    BrandMessagingOptimizationEnabled: true,
    CustomerReviewsEnhancementEnabled: true,
    EnhancedTechnicalDetailsEnabled: true,
    IngredientQualityDescriptionsEnabled: true,
    PackagingTextRefinementEnabled: true,
    DynamicProductDescriptionsEnabled: true,
    InteractiveButtonTextsEnabled: true,
    SmallTaglinesEnabled: true,
    ABtestingCreativityLevel: 3,
    ABtestingVariantsTnumber: 5,
    ABtestingConfidenceLevel: 85,
    ABtestingEarlyElimination: 65,
    ABtestingBrandVoicePreservation: 5,
    ABtestingTrafficPercentageForVariants: 1,
    EtradeAIautoallowed: true, 
  };


  const [settings, setSettings] = useState(defaultSettings);
  const [showAiABTestSwitch, setShowAiABTestSwitch] = useState(false);
  const [modifiedUrl, setModifiedUrl] = useState('');
  const [urls, setUrls] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  
  useEffect(() => {
    let initialUrl = props.context?.url || '';
    let isUrlFromCookies = false;

    if (!initialUrl) {
      const domainName = Cookies.get('domainName');
      if (domainName) {
        initialUrl = `${domainName} Whole website`;
        setShowAiABTestSwitch(true);
        isUrlFromCookies = true;
      }
    } else {
      setShowAiABTestSwitch(false);
    }

    setModifiedUrl(initialUrl);

    const fetchPageList = async () => {
      let pageList = await getPageListFromSettings();
      if (pageList && pageList.length > 0) {
        pageList.sort((a, b) => {
          if (a.includes("Whole website")) return -1;
          if (b.includes("Whole website")) return 1;
          return a.length - b.length;
        });

        const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
        if (wholeWebsiteUrls.length > 1) {
          const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
          pageList = pageList.filter(url => !url.includes("Whole website"));
          pageList.unshift(firstWholeWebsiteUrl);
        }

        setUrls(pageList);

        const wholeSiteUrl = pageList.find(url => url.includes("Whole website"));
        setSelectedUrl(wholeSiteUrl || pageList[0]);
        setShowWholeWebsiteWarning(!!wholeSiteUrl);
      }
    };

    fetchPageList();

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(initialUrl);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();

    return () => {
      if (isUrlFromCookies) {
      }
    };
  }, [props.context?.url]);

  const handleSettingChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);

    const payload = {
      url: selectedUrl || modifiedUrl,
      data: updatedSettings,
    };

    sendFeatureStatusToServer2(payload).catch(error => {
      console.error(error);
      message.error('Failed to update settings.');
    });
  };

  const handleUrlChange = (value) => {
    setSelectedUrl(value);
    setShowWholeWebsiteWarning(value.includes("Whole website"));
    setModifiedUrl(value);

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(value);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();
  };




  return (
    <div data-seatextdont  style={{ display: 'flex', justifyContent: 'center' }} >
      <div style={{ width: '100%', minWidth: '1086px', justifyContent: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }} >
          <div style={{ width: '100%' }}>
            <Card bordered={false} style={{ width: '100%' }}>
            <div className="ai-card-content" style={{ 
                marginBottom: '30px', 
                marginTop: '0px', 
                backgroundColor: '#f8f8f8', 
                minHeight: '350px', 
                padding: '10px',
                border: '1px solid #A9A9A9' // Changed to dark grey
              }}>
                <div className="lottie-container" style={{ float: 'left', marginRight: '20px', marginLeft: '20px', marginTop: '40px' }}>
                  <LottieAnimation
                    animationPath="https://lottie.host/f1888009-497f-4f39-957c-2dee57224074/cIuSvSj7bw.json"
                    width={500}
                    height={300}
                  />
                </div>
                <div className="ai-text-content">
                  <div className="ai-title-container">
                    <h2 className="ai-title" style={{ fontSize: '22px', marginBottom: '10px', marginTop: '40px' }}>Online-store AI product descriptions text optimisation</h2>
                  </div>
                  <div className="ai-description" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
                    This AI's core feature is boosting online store sales by optimizing product descriptions using AI. It automatically generates and A/B tests multiple versions of product descriptions, identifying the most compelling language to drive purchases. This results in significant sales increases of up to 40%. Its unique strength lies in its specialized Large Language Model trained specifically on high-converting e-commerce content, enabling it to craft product descriptions that outperform those generated by general AI model.
                  </div>
                  <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                    <Switch
                      checked={settings.ProductDescOptEnabled}
                      onChange={(checked) => handleSettingChange('ProductDescOptEnabled', checked)}
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                    />
                    <div data-seatextonly><span style={{ marginLeft: '10px', fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>Activate AI Optimization / Deactivate AI Optimization</span></div>
                  </div>
                  
                  <Form layout="vertical" style={{ marginTop: '20px', marginRight: '10px' }}>
                    <Select
                      showSearch
                      style={{ width: '100%', marginBottom: '20px' }}
                      placeholder="Select a URL"
                      value={selectedUrl}
                      onChange={handleUrlChange}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {urls.map(url => (
                        <Option value={url} key={url}>{url}</Option>
                      ))}
                    </Select>
                    {showWholeWebsiteWarning && (
                      <div style={{ margin: '10px 0', padding: '10px', backgroundColor: '#f8f8f8', border: '1px solid #e8e8e8' }}>
                        You are editing settings for the entire website. Changes made here will affect all pages on your website,
                        including those added later. If you want to change settings for a particular URL and have specific settings for it,
                        please select a URL from the dropdown list above first.
                      </div>
                    )}
                  </Form>


                    {/* New block for online AI stores */}
  
       
         
            <Row align="middle" gutter={[16, 16]}>
              <Col span={2}>
                <Switch
                  checked={settings.EtradeAIautoallowed}
                  onChange={(checked) => handleSettingChange('EtradeAIautoallowed', checked)}
                  checkedChildren="ON"
                  unCheckedChildren="OFF"
                  style={{ width: '55px' }}
                />
              </Col>
              <Col span={20}>
              <Text>
  Automatically approve AI-generated content for this URL. 
  Turn off if you wish to manually approve all AI-generated variants before they are displayed to your visitors.
  <strong> WARNING! This can be time-consuming, as you will need to manually review each AI variant on every page, which may take hours for some clients.</strong>
</Text>
              </Col>
            </Row>
          
                </div>


                
              </div>
              

       



              </Card>

              


  


          
              <div style={{ marginBottom: '30px' }}>

  <div style={{ display: 'flex', alignItems: 'flex-start', backgroundColor: '#f8f8f8', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
    <img src="https://login.seatext.com/2.jpg" alt="SEA Text Tags" style={{ width: '380px', marginRight: '40px' }} />
    <div><h4 style={{ marginBottom: '20px', fontSize: '22px' }}>Control what text AI optimizes: How to define which text the AI can fine-tune and which it cannot?</h4>
      <h5 style={{ marginTop: '0' }}>Method 1:  Fine-tune Control with Special Tags by using `sea​textdont` and 'sea​textonly' inside your text</h5>
      <p>
      The best way to control AI optimization is by using special tags, we recommend using the `sea​textdont` and 'sea​textonly' special words inside your website editor as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. This method offers the most precise control over AI's behavior. Using the section-based controls (Method 2) is less precise.
      </p> 
      <div style={{ height: '30px' }}></div>

      <h5>Method 2:  Choose Website Sections for AI Optimization (less precise)</h5>
  <p>
  If you can't use the special tags or prefer a quicker approach, you can use the sliders below to select specific sections of your website for AI optimization.
  </p>
    </div>
  </div>


</div>


      <div style={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%' }}>
        <div className="frame" style={{ width: '100%' }}>
      <div className="online-sotre-ais" style={{ width: '100%' }} >
        <div className=".div-2" style={{ width: '100%' }} />
        <div className="div" style={{ width: '100%' }} />
        <div className="div-2" style={{ width: '100%' }} />
        <div className="div" style={{ width: '100%' }} />
        <div className="div-2" style={{ width: '100%' }} />
        <div className="div" style={{ width: '100%' }} />
        <div className="div-2" style={{ width: '100%' }} />
        <div className="div" style={{ width: '100%' }} />
        <div className="div-2" style={{ width: '100%' }}/>
        <Switch
            checked={settings.ProductNameCustomizationEnabled}
            onChange={(checked) => handleSettingChange('ProductNameCustomizationEnabled', checked)}
            className="switch-instance"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper">Product Name Customization</div>
          <p className="enable-AI-to-enhance">Enable AI to enhance product names by appending descriptors such as "innovative," "refreshing," or "robust." This feature leverages AI's contextual understanding to aptly complement your product names. However, be aware that some suggestions may be suboptimal and require manual review.</p>
          <Switch
            checked={settings.BrandMessagingOptimizationEnabled}
            onChange={(checked) => handleSettingChange('BrandMessagingOptimizationEnabled', checked)}
            className="design-component-instance-node"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-2">Brand Messaging Optimization</div>
          <p className="AI-can-subtly-refine">AI can subtly refine your brand messages and slogans without overhauling the core message. As the most impactful yet sensitive tool, we recommend using it with caution. Monitor the variant editor to review the AI's outputs and their effectiveness.</p>
          <Switch
            checked={settings.CustomerReviewsEnhancementEnabled}
            onChange={(checked) => handleSettingChange('CustomerReviewsEnhancementEnabled', checked)}
            className="switch-2"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-3">Customer Reviews Enhancement</div>
          <p className="p">Our AI can refine customer reviews by improving language, correcting grammatical errors, and moderating tone. It enhances readability and professionalism without altering the original sentiment. Note: If you wish to remove negative reviews, this must be done manually.</p>
          <Switch
            checked={settings.EnhancedTechnicalDetailsEnabled}
            onChange={(checked) => handleSettingChange('EnhancedTechnicalDetailsEnabled', checked)}
            className="switch-3"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-4">Enhanced Technical Details</div>
          <p className="improve-your-product">Improve your product's technical specifications with AI. It can optimize language and adapt measurements to relevant units (e.g., converting to imperial units for the US market). Rest assured, numerical data remains unchanged as we prioritize accuracy.</p>
          <Switch
            checked={settings.IngredientQualityDescriptionsEnabled}
            onChange={(checked) => handleSettingChange('IngredientQualityDescriptionsEnabled', checked)}
            className="switch-4"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-5">Ingredient and Quality Descriptions</div>
          <p className="text-wrapper-6">AI can fine-tune descriptions related to ingredients and manufacturing quality, aligning closely with your original style to preserve your brand’s identity.</p>
          <Switch
            checked={settings.PackagingTextRefinementEnabled}
            onChange={(checked) => handleSettingChange('PackagingTextRefinementEnabled', checked)}
            className="switch-5"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text />
        <div className="text-wrapper-7">Packaging Text Refinement</div>
        <p className="AI-can-fine-tune-the">
          AI can fine-tune the text on your packaging, which is crucial for international shipments or when adding
          special items. For luxury packaging, precise text adjustments can significantly enhance your brand&#39;s
          presentation.
        </p>
        <Switch
            checked={settings.DynamicProductDescriptionsEnabled}
              onChange={(checked) => handleSettingChange('DynamicProductDescriptionsEnabled', checked)}
            className="switch-7"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
        <div className="text-wrapper-8">Dynamic Product Descriptions</div>
        <p className="text-wrapper-9">
          Our AI can generate variations of your product descriptions, emphasizing different benefits. This allows
          you to discover what resonates most with your customers and refine your messaging accordingly. You can
          maintain your brand’s voice or increase creativity via an adjustable slider, providing more flexibility in
          expression
        </p>
        <Switch
            checked={settings.InteractiveButtonTextsEnabled}
            onChange={(checked) => handleSettingChange('InteractiveButtonTextsEnabled', checked)}
            className="switch-8"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
          <div className="text-wrapper-10">Interactive Button Texts</div>
          <p className="AI-can-analyze-and">AI can analyze and test various texts for interactive buttons like "Buy Now." It generates thousands of alternatives, presenting a unique version to each user per session. This ensures a dynamic and engaging user experience without creating inconsistencies across different pages.</p>
          <Switch
            checked={settings.SmallTaglinesEnabled}
            onChange={(checked) => handleSettingChange('SmallTaglinesEnabled', checked)}
            className="switch-9"
            disabled={false}
            icon={false}
            loading={false}
            size="medium"
            text
          />
        <div className="text-wrapper-11">Small taglines</div>
        <p className="text-wrapper-12">
          Small taglines play a crucial role in capturing customer interest. Our AI can generate several variations of
          your existing tagline and create new ones inspired by the content of your page. This enables you to initiate
          A/B testing with multiple options, helping you determine the most effective tagline to engage your audience.
        </p>
      </div>
      <img className="product-name" alt="Product name" src="https://login.seatext.com/product-name-customization-img-1.png" />
      <img className="small-taglines-img" alt="Small taglines img" src="https://login.seatext.com/small-taglines-img-1.png" />
      <img className="interactive-button" alt="Interactive button" src="https://login.seatext.com/interactive-button-texts-img-1.png" />
      <img className="dynamic-product" alt="Dynamic product" src="https://login.seatext.com/dynamic-product-descriptions-img-1.png" />
      <img className="packaging-text" alt="Packaging text" src="https://login.seatext.com/packaging-text-refinement-img-1.png" />
      <img className="enhanced-technical" alt="Enhanced technical" src="https://login.seatext.com/enhanced-technical-details-img-1.png" />
      <img className="ingredient-and" alt="Ingredient and" src="https://login.seatext.com/ingredient-and-quality-descriptions-img-1.png" />
      <img className="customer-reviews" alt="Customer reviews" src="https://login.seatext.com/customer-reviews-enhancement-img-1.png" />
      <img className="brand-messaging" alt="Brand messaging" src="https://login.seatext.com/brand-messaging-optimization-img-1.png" />
    </div>
  





          

            
  

  
<Text align="left" style={{ fontSize: '24px', fontWeight: 'bold' }}>
  General AI Settings
</Text>

            {/* AI Creativity Level and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Max Creativity',
                    2: 'High',
                    3: 'Medium',
                    4: 'Low',
                    5: 'Minimal Changes',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
                </Text>
              </Col>
            </Row>
  
            {/* Number of Variants to Create and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                   
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>
  
            {/* Preserve Your Brand Voice and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                    
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>
  
            {/* A/B Testing Settings */}
            {/* Confidence Level for A/B Testing and its description */}
    
<Text align="left" style={{ fontSize: '24px', fontWeight: 'bold' }}>
A/B Testing Settings
</Text>

            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>
  
            {/* Early Elimination of Underperforming Variants and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={1}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>
  

            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}> {/* Increased vertical space for the last slider */}
                    <Col span={12}>
                        <Text>Introduce AI variants slowly to your visitors:</Text>
                        <Slider
                            min={1}
                            max={6}
                            step={1}
                            marks={{
                                1: '100%',
                                2: '80%',
                                3: '60%',
                                4: '40%',
                                5: '10%',
                                6: '0% ',
                            }}
                            defaultValue={1}
                            onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                           tooltipVisible={false}
                        />
                    </Col>
                    <Col span={12} style={{ paddingLeft: '50px' }}>
                        <Text>
                            This percantage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly.  Your original text wil be tested in A/B mix in any case as one of variant.
                        </Text>
                    </Col>
                </Row>
                </div>
                </div>
                </div>
                </div>  </div>  
        
      
    </div>
  );
}

export default Etrade;