import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Switch, Select, Form, message } from 'antd';
import Cookies from 'js-cookie';
import { sendFeatureStatusToServer2, fetchSettingsFromServer, getPageListFromSettings } from './utils';
import LottieAnimation from './LottieAnimation';
import "./style.css";

const { Title, Text } = Typography;
const { Option } = Select;

function Mobile(props) {
    // Define default settings
    const defaultSettings = {
      ABtestingCreativityLevel: 3,
      ABtestingVariantsTnumber: 5,
      ABtestingConfidenceLevel: 85,
      ABtestingEarlyElimination: 65,
      ABtestingBrandVoicePreservation: 5,
      ABtestingTrafficPercentageForVariants: 1,
      MobileTechinfoEnhancementEnabled: true,
      MobileFriendlyReviewsEnhancementEnabled: true,
      MobileOptimizedCTAsEnabled: true,
      MobileContentOptimizationEnabled: true,
      MobileTitlesEnhancementEnabled: true,
      MobileOptEnabled: false,
      ProductDescOptEnabled: false,
      EtradeAIautoallowed: false,
  };

  // State hooks
  const [settings, setSettings] = useState(defaultSettings);
  const [selectedUrl, setSelectedUrl] = useState(''); 
  const [urls, setUrls] = useState([]);
  const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Handler for changing settings
  const handleSettingChange = (key, value) => {
      const updatedSettings = { ...settings, [key]: value };
      setSettings(updatedSettings);

      const payload = {
          url: selectedUrl,
          data: updatedSettings,
      };

      sendFeatureStatusToServer2(payload).catch(error => {
          console.error(error);
          message.error('Failed to update settings.');
      });
  };

  // URL Change Handler
  const handleUrlChange = (value) => {
    setSelectedUrl(value);
    setShowWholeWebsiteWarning(value.includes("Whole website"));

    fetchSettingsFromServer(value)
      .then(serverSettings => {
        if (serverSettings) { // Only update if serverSettings is valid
          setSettings(prevSettings => ({ ...prevSettings, ...serverSettings }));
        }
      })
      .catch(error => {
        console.error('Failed to fetch settings:', error);
        message.error('Failed to load URL settings.');
      });
  };

  // Initial setup and data fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        let pageList = await getPageListFromSettings();
  
        // Deduplicate "Whole website" entries (keep only one)
        const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
        if (wholeWebsiteUrls.length > 1) {
          const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
          pageList = pageList.filter(url => !url.includes("Whole website"));
          pageList.unshift(firstWholeWebsiteUrl); // Add the first occurrence back
        }
  
        // Sort URLs *after* deduplication
        pageList.sort((a, b) => {
          if (a.includes("Whole website")) return -1;
          if (b.includes("Whole website")) return 1;
          return a.length - b.length;
        });
  
        setUrls(pageList);
  
        let initialUrl = props.context?.url;
  
        if (!initialUrl) {
          const domainName = Cookies.get('domainName');
          initialUrl = domainName ? `${domainName} Whole website` : pageList[0] || ''; 
        }
  
        setSelectedUrl(initialUrl);
        setShowWholeWebsiteWarning(initialUrl.includes("Whole website"));
  
        const serverSettings = await fetchSettingsFromServer(initialUrl);
        if (serverSettings) {
          setSettings(prevSettings => ({ ...prevSettings, ...serverSettings }));
        }
  
        setIsLoading(false);
  
      } catch (error) {
    
        console.error('Failed to fetch initial data:', error);
        message.error('Failed to load initial settings.');
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);




  // If still loading, you might want to return a loading indicator
  if (isLoading) {
      return <div>Loading...</div>;
  }




  return (




    <div data-seatextdont>
    <div style={{ width: '100%', maxWidth: '1200px', minWidth: '1086px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }} >
        <div style={{ width: '100%' }}>
          <Card bordered={false} style={{ width: '100%' }}>
          <div className="ai-card-content" style={{ 
              marginBottom: '30px', 
              marginTop: '0px', 
              backgroundColor: '#f8f8f8', 
              minHeight: '350px', 
              padding: '0px',
              border: '1px solid #A9A9A9' // Changed to dark grey
            }}>
              <div className="lottie-container" style={{ float: 'left', marginRight: '20px', marginLeft: '20px', marginTop: '40px' }}>
                <LottieAnimation
                  animationPath="https://lottie.host/a57fc31f-6207-4375-859c-22347151cdab/Cd2HOPFeC3.json"
                  width={500}
                  height={300}
                />
              </div>
              <div className="ai-text-content">
                <div className="ai-title-container">
                  <h2 className="ai-title" style={{ fontSize: '22px', marginBottom: '10px', marginTop: '40px' }}>Advanced AI model for mobile adaptation</h2>
                </div>
                <div className="ai-description" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
                This AI model boosts mobile conversions by automatically optimizing website content for smaller screens. It transforms desktop content into shorter, more impactful mobile versions, ensuring easy readability and engagement. The process also includes automated A/B testing to find the best-performing version, further increasing conversions. Since 90% of websites use the same content for both mobile and desktop, making mobile sites harder to read, this model trims text where possible and shows optimized versions to mobile users automatically.
                </div>
                <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                  <Switch
                    checked={settings.MobileOptEnabled}
                    onChange={(checked) => handleSettingChange('MobileOptEnabled', checked)}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                  />
                  <div data-seatextonly><span style={{ marginLeft: '10px', fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>Activate AI Optimization / Deactivate AI Optimization</span></div>
                </div>
                
                <Form layout="vertical" style={{ marginTop: '20px', marginRight: '10px' }}>
                  <Select
                    showSearch
                    style={{ width: '100%', marginBottom: '20px' }}
                    placeholder="Select a URL"
                    value={selectedUrl}
                    onChange={handleUrlChange}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {urls.map(url => (
                      <Option value={url} key={url}>{url}</Option>
                    ))}
                  </Select>
                  {showWholeWebsiteWarning && (
                    <div style={{ margin: '10px 0', padding: '10px', backgroundColor: '#f8f8f8', border: '1px solid #e8e8e8' }}>
                      You are editing settings for the entire website. Changes made here will affect all pages on your website,
                      including those added later. If you want to change settings for a particular URL and have specific settings for it,
                      please select a URL from the dropdown list above first.
                    </div>
                  )}
                </Form>


                  {/* New block for online AI stores */}

     
       
          <Row align="middle" gutter={[16, 16]}>
            <Col span={2}>
              <Switch
                checked={settings.EtradeAIautoallowed}
                onChange={(checked) => handleSettingChange('EtradeAIautoallowed', checked)}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                style={{ width: '55px' }}
              />
            </Col>
            <Col span={20}>
            <Text>
Automatically approve AI-generated content for this URL. 
Turn off if you wish to manually approve all AI-generated variants before they are displayed to your visitors.
<strong> WARNING! This can be time-consuming, as you will need to manually review each AI variant on every page, which may take hours for some clients.</strong>
</Text>
            </Col>
          </Row>
        
              </div>


              
            </div>
            

     



            </Card>

            





        
            <div style={{ marginBottom: '30px' }}>

<div style={{ display: 'flex', alignItems: 'flex-start', backgroundColor: '#f8f8f8', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
  <img src="https://login.seatext.com/2.jpg" alt="SEA Text Tags" style={{ width: '380px', marginRight: '40px' }} />
  <div><h4 style={{ marginBottom: '20px', fontSize: '22px' }}>Control what text AI optimizes: How to define which text the AI can fine-tune and which it cannot?</h4>
    <h5 style={{ marginTop: '0' }}>Method 1:  Fine-tune Control with Special Tags by using `sea​textdont` and 'sea​textonly' inside your text</h5>
    <p>
    The best way to control AI optimization is by using special tags, we recommend using the `sea​textdont` and 'sea​textonly' special words inside your website editor as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. This method offers the most precise control over AI's behavior. Using the section-based controls (Method 2) is less precise.
    </p> 
    <div style={{ height: '30px' }}></div>

    <h5>Method 2:  Choose Website Sections for AI Optimization (less precise)</h5>
<p>
If you can't use the special tags or prefer a quicker approach, you can use the sliders below to select specific sections of your website for AI optimization.
</p>
  </div>
</div>


</div>














































<div
  style={{
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexGrow: 0,
    flexShrink: 0,
    position: "relative",
    background: "#fff",
  }}
>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: '100%',
      height: 280,
      position: "relative",
      background: "#f5f5f5",
    }}
  >
    <div
      style={{
        width: '100%',
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#f5f5f5",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileTitlesEnhancementEnabled}
        onChange={(checked) => handleSettingChange('MobileTitlesEnhancementEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 468,
        position: "absolute",
        left: 438,
        top: 32,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Titles, Taglines, and Short Phrases
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 60,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Craft captivating titles, taglines, and short phrases optimized for mobile screens. Our AI
      understands context and tailors suggestions specifically for mobile users, ensuring your
      content resonates with your audience on the go. While AI offers valuable insights, a manual
      review ensures every suggestion aligns perfectly with your brand and tone.
    </p>
    <img
      src="https://login.seatext.com/titles-taglines-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 27,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: '100%',
      height: 280,
      position: "relative",
      background: "#fff",
    }}
  >
    <div
      style={{
        width: '100%',
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#fff",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileContentOptimizationEnabled}
        onChange={(checked) => handleSettingChange('MobileContentOptimizationEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 438,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile Content Optimization
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Enhance your website content for mobile users. Adjust the AI Creativity Level to seamlessly
      align with your brand's voice and tone. Our AI engine generates a variety of mobile-optimized
      variants, rigorously testing them through A/B testing to identify the most effective content.
      This ensures your message resonates on mobile while reinforcing your brand identity.
    </p>
    <img
      src="https://login.seatext.com/website-content-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: '100%',
      height: 280,
      position: "relative",
      background: "#f5f5f5",
    }}
  >
    <div
      style={{
        width: '100%',
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#f5f5f5",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileOptimizedCTAsEnabled}
        onChange={(checked) => handleSettingChange('MobileOptimizedCTAsEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 438,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile-Optimized Calls-to-Action
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Maximize conversions with AI-rewritten CTAs and buttons tailored for mobile. Our AI engine
      analyzes your website content and industry to craft compelling calls to action specifically
      designed for mobile users. Easily A/B test different options to discover what resonates best
      with your target audience, ensuring every tap turns into a valuable conversion.
    </p>
    <img
      src="https://login.seatext.com/ctas-buttons-ctas.png"
      style={{
        width: 362,
        height: 253,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: '100%',
      height: 280,
      position: "relative",
      background: "#f5f5f5",
    }}
  >
    <div
      style={{
        width: '100%',
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#fff",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileFriendlyReviewsEnhancementEnabled}
        onChange={(checked) => handleSettingChange('MobileFriendlyReviewsEnhancementEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 438,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile-Friendly Customer Reviews
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 438,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Our AI technology enhances customer reviews, making them shine on mobile screens. We refine
      language, correct grammar, and moderate tone to ensure reviews are easy to read and
      professionally presented while preserving the authenticity of the original sentiment. Please
      note that if you wish to remove negative reviews, this must be done manually.
    </p>
    <img
      src="https://login.seatext.com/customer-reviews-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
  <div
    style={{
      flexGrow: 0,
      flexShrink: 0,
      width: '100%',
      height: 280,
      position: "relative",
      background: "#f6f6f6",
    }}
  >
    <div
      style={{
        width: '100%',
        height: 280,
        position: "absolute",
        left: "-0.5px",
        top: "-0.5px",
        background: "#f6f6f6",
      }}
    />
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "absolute",
        left: 1010,
        top: 28,
        gap: 5,
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: 16,
        background: "#1890ff",
      }}
    >
      <Switch
        checked={settings.MobileTechinfoEnhancementEnabled}
        onChange={(checked) => handleSettingChange('MobileTechinfoEnhancementEnabled', checked)}
        disabled={false}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        icon={false}
        loading={false}
        size="medium"
        text
      />
    </div>
    <p
      style={{
        width: 462,
        position: "absolute",
        left: 440,
        top: 28,
        fontSize: 22,
        textAlign: "left",
        color: "rgba(0,0,0,0.85)",
      }}
    >
      Mobile-Optimized Technical Content
    </p>
    <p
      style={{
        width: 464,
        position: "absolute",
        left: 440,
        top: 56,
        fontSize: 16,
        textAlign: "left",
        color: "rgba(0,0,0,0.45)",
      }}
    >
      Our AI enhances the presentation of technical information, making complex topics clear and
      engaging on mobile. We refine wording, correct grammar, and optimize readability while
      preserving critical technical details. A/B testing ensures the content resonates with your
      mobile audience, making intricate information accessible and digestible on smaller screens.
    </p>
    <img
      src="https://login.seatext.com/technical-documentation-mobile.png"
      style={{
        width: 362,
        height: 252,
        position: "absolute",
        left: 27,
        top: 25,
        objectFit: "cover",
      }}
    />
  </div>
</div>



<div style={{ height: '15px' }}></div>



            {/* AI Creativity Level and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Max',
                    2: 'High',
                    3: 'Medium',
                    4: 'Low',
                    5: 'Min',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A lower setting means the AI will generate content that's significantly different from the original, while a higher setting limits creativity to minimal changes.
                </Text>
              </Col>
            </Row>
  
            {/* Number of Variants to Create and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                   
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>
  
            {/* Preserve Your Brand Voice and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                    
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>
  
            {/* A/B Testing Settings */}
            {/* Confidence Level for A/B Testing and its description */}
            <Title level={2} align="center" style={{ marginBottom: '50px' }}>A/B Testing Settings</Title>
  
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>
  
            {/* Early Elimination of Underperforming Variants and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={1}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>
  

            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}> {/* Increased vertical space for the last slider */}
                    <Col span={12}>
                        <Text>Introduce AI variants slowly to your visitors:</Text>
                        <Slider
                            min={1}
                            max={6}
                            step={1}
                            marks={{
                                1: '100%',
                                2: '80%',
                                3: '60%',
                                4: '40%',
                                5: '10%',
                                6: '0% ',
                            }}
                            defaultValue={1}
                            onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                           tooltipVisible={false}
                        />
                    </Col>
                    <Col span={12} style={{ paddingLeft: '50px' }}>
                        <Text>
                            This percantage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly.  Your original text wil be tested in A/B mix in any case as one of variant.
                        </Text>
                    </Col>
                </Row>



                </div>

                </div>

                </div>

                </div>

  
         
 
  );
  
};

export default Mobile;