import React, { useState, useEffect } from 'react';
import { Typography, Card, Select, Form, Button } from 'antd';
import { 
    getPageListFromSettingsfast, 
    fetchChartData, 
    getMainWebsiteLanguage 
} from './utils';
import URLDataCardLight from './URLDataCardLight';

const { Option } = Select;

const TIME_RANGES = {
  '30': 30,
  '90': 90,
  '120': 120,
  '180': 180
};

const generateChartDataWithFakeDays = (originalChartData) => {
    let fakeChartData = [];
    if (originalChartData.length <= 1) {
        const originalData = originalChartData.length === 1 ? originalChartData[0] : { conversions: 0, views: 0 };
        for (let i = 0; i < 3; i++) {
            fakeChartData.push({
                ...originalData,
                day: i,
            });
        }
    } else {
        fakeChartData = originalChartData;
    }
    return fakeChartData;
};

export default function URLGraphContainer() {
    const [urls, setUrls] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState(null);
    const [urlChartData, setUrlChartData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [mainLanguage, setMainLanguage] = useState(null);
    const [selectedRange, setSelectedRange] = useState('30');

    useEffect(() => {
        const fetchPageList = async () => {
            try {
                let pageList = await getPageListFromSettingsfast();
                if (pageList && pageList.length > 0) {
                    pageList.sort((a, b) => {
                        if (a.includes("Whole website")) return -1;
                        if (b.includes("Whole website")) return 1;
                        return a.length - b.length;
                    });
                    
                    const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
                    if (wholeWebsiteUrls.length > 1) {
                        const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
                        pageList = pageList.filter(url => !url.includes("Whole website"));
                        pageList.unshift(firstWholeWebsiteUrl);
                    }

                    setUrls(pageList);
                    setSelectedUrl(pageList[0]);
                    const language = await getMainWebsiteLanguage(pageList[0]);
                    setMainLanguage(language);
                }
            } catch (error) {
                console.error('Error fetching page list:', error);
            }
        };

        fetchPageList();
    }, []);

    useEffect(() => {
        const loadChartData = async () => {
            if (!selectedUrl) return;

            setIsLoading(true);
            try {
                const language = mainLanguage || await getMainWebsiteLanguage(selectedUrl);
                if (language && language !== mainLanguage) {
                    setMainLanguage(language);
                }
                
                const chartData = await fetchChartData(selectedUrl, language)
                    .then(generateChartDataWithFakeDays)
                    .then(data => data.map(dataPoint => ({
                        ...dataPoint,
                        conversionRate: dataPoint.views !== 0 ? (dataPoint.conversions / dataPoint.views * 100) : 0,
                    })));

                const totalViews = chartData.reduce((sum, item) => sum + item.views, 0);
                const totalConversions = chartData.reduce((sum, item) => sum + item.conversions, 0);
                const conversionRate = totalViews !== 0 ? (totalConversions / totalViews * 100) : 0;

                setUrlChartData(prev => ({
                    ...prev,
                    [selectedUrl]: {
                        chartData,
                        views: totalViews,
                        conversions: totalConversions,
                        conversionRate,
                    }
                }));
            } catch (error) {
                console.error(`Error fetching chart data for ${selectedUrl}:`, error);
            } finally {
                setIsLoading(false);
            }
        };

        loadChartData();
    }, [selectedUrl, mainLanguage]);

    return (
        <div className="p-6">
            <Card 
                className="mb-6"
                style={{ 
                    backgroundColor: '#f8f8f8', 
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: '#A9A9A9',
                    borderBottom: 'none',
                    borderRadius: 0
                }}
            >
              <h2 style={{ 
                    fontFamily: 'Roboto',
                    fontSize: '22px',
                    fontWeight: 500,
                    marginBottom: '16px',
                    color: '#1a365d'
                }}>
                    Choose page to analyze AI performance
                </h2>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Item 
                        style={{ 
                            width: '500px',
                            marginBottom: 0,
                            marginRight: '16px'
                        }}
                    >
                        <Select
                            value={selectedUrl}
                            onChange={setSelectedUrl}
                            placeholder="Select a URL to analyze"
                        >
                            {urls.map(url => (
                                <Option key={url} value={url}>{url}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {Object.keys(TIME_RANGES).map(range => (
                        <Button
                            key={range}
                            type={selectedRange === range ? 'primary' : 'default'}
                            onClick={() => setSelectedRange(range)}
                            style={{ 
                                backgroundColor: selectedRange === range ? '#1a365d' : '#ffffff',
                                color: selectedRange === range ? '#ffffff' : '#1a365d',
                                borderColor: '#1a365d',
                                marginRight: '8px'
                            }}
                        >
                            {range} Days
                        </Button>
                    ))}
                </div>
            </Card>

            {selectedUrl && (
                <URLDataCardLight
                    url={selectedUrl}
                    conversionData={urlChartData[selectedUrl]}
                    isLoading={isLoading}
                    language={mainLanguage}
                    selectedRange={selectedRange}
                    timeRanges={TIME_RANGES}
                />
            )}
        </div>
    );
}