import React, { useState, useEffect, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import WelcomePage from './WelcomePage';


///import Plan1Page from './Plan1Page';
import LicenseAgreementPage from './LicenseAgreementPage'; // Make sure the path is correct
import FAQPage from './FAQPage'
import AIMarketingPersonalisation from './AIMarketingPersonalisation';
///import { clarity } from 'react-microsoft-clarity';

import Seatextdemo from './seatextdemo';
import Landingpages from './Landingpages';
import TranslationSettingsPage from './Settingstranslation';
import SettingsPage2 from './SettingsPage2'; // Make sure to import the SettingsPage2 component

import InstallationInstructions from './InstallationInstructions';
import VariantsEditPage from './VariantEdit';
import PlanSelectAndPayment from './PlanSelectAndPayment';
import SettingsPage from './SettingsPage';
import ImageUploader from './upload';
import VariantNext from './variantnext';
import BetaFeatureAccessDenied from './Beta';
import Etrade from './etrade';
import YoutubeVideoPage from './YoutubeVideoPage';
////import './index.css'; // Assuming this file is in the same directory
import 'carbon-components/css/carbon-components.min.css';
///import '@carbon/react/scss/globals/scss/styles.scss';
import Mobile from './mobile';
import AISalesPersonalisation from './AIsalespersonalisation';
import ConversionSettingsPage from './ConversionSettingspage';





export const UserCredentialsContext = createContext(null);

export default function App() {
  const [userCredentials, setUserCredentials] = useState(null);
  
  useEffect(() => {
    // Microsoft Clarity Initialization
    ///const projectToken = 'k6n7mbhlpc';
    ///clarity.init(projectToken);

    // Injecting Stripe script
    const stripeScript = document.createElement('script');
    stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
    stripeScript.async = true;
    document.body.appendChild(stripeScript);

    // Seatext Initialization (remains unchanged)
    const seatextId = "96733aab-aaad-4e0e-90ec-5c899e524514";
    localStorage.setItem('seatext_id', seatextId);
    window.globalSeatextID = seatextId;
    const seatextScript = document.createElement('script');
    seatextScript.src = `https://login.seatext.com/seatext.js?id=${seatextId}`;
    seatextScript.async = true;
    document.body.appendChild(seatextScript);

// Injecting PostHog script
const posthogScript = document.createElement('script');
posthogScript.innerHTML = `
  !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="init push capture register register_once register_for_session unregister unregister_for_session getFeatureFlag getFeatureFlagPayload isFeatureEnabled reloadFeatureFlags updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures on onFeatureFlags onSessionId getSurveys getActiveMatchingSurveys renderSurvey canRenderSurvey getNextSurveyStep identify setPersonProperties group resetGroups setPersonPropertiesForFlags resetPersonPropertiesForFlags setGroupPropertiesForFlags resetGroupPropertiesForFlags reset get_distinct_id getGroups get_session_id get_session_replay_url alias set_config startSessionRecording stopSessionRecording sessionRecordingStarted loadToolbar get_property getSessionProperty createPersonProfile opt_in_capturing opt_out_capturing has_opted_in_capturing has_opted_out_capturing clear_opt_in_out_capturing debug".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
  posthog.init('phc_ICnJEs7MQ12DBneUlQU0jttGX3hgkgc0hh3pa37y5H4', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only'
  });
`;
document.body.appendChild(posthogScript);



    // Since there's no cleanup for these scripts, no cleanup function is returned
  }, []);



  return (
    <UserCredentialsContext.Provider value={setUserCredentials}>
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={userCredentials ? <Navigate to="/welcome" /> : <LoginPage />} 
          />
          {/* Main welcome route that handles all sub-routes */}
          <Route path="/welcome/*" element={<WelcomePage />} />
          
          {/* Keep these routes for direct access without WelcomePage layout */}
          <Route path="/license-agreement" element={<LicenseAgreementPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/youtube-video" element={<YoutubeVideoPage />} />
          {/* Other standalone routes that don't need WelcomePage layout */}
        </Routes>
      </Router>
    </UserCredentialsContext.Provider>
  );
}