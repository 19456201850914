import React, { useState, useEffect } from 'react';
import {
  List, Typography, Input, Button, Modal
} from 'antd';
import { SaveOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getPersonalisedVariantsForUrl, updateVariantOnServer } from './utils';
import { updatePersonalizedVariantOnServer } from './utils';

const { TextArea } = Input;
const { Title } = Typography;
const PAGE_SIZE = 300;

function EditableTextArea({ initialValue, onSave }) {
  const [tempValue, setTempValue] = useState(initialValue);

  const handleChange = (e) => {
    setTempValue(e.target.value);
    onSave(e.target.value);
  };

  return (
    <TextArea
      value={tempValue}
      onChange={handleChange}
      autoSize={{ minRows: 1, maxRows: 8 }}
      style={{ marginBottom: '8px', width: '100%' }}
    />
  );
}

const cleanUrl = (url) => url ? url.replace(/ Whole website$/, '').trim() : '';

export default function PersonalizedVariantEditor({ visible, onClose, url, personalizationId }) {
  const [variants, setVariants] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const selectedLanguage = "English";
  const cleanedUrl = cleanUrl(url);




  useEffect(() => {
    return () => {
      setVariants([]);
      setPageIndex(0);
    };
  }, [url, personalizationId]);

  useEffect(() => {
    if (visible) {
      fetchVariants();
    }
  }, [visible, url, personalizationId]);


  useEffect(() => {
    if (!visible) {
      setVariants([]);
      setPageIndex(0);
    }
  }, [visible]);


  const fetchVariants = async () => {
    if (cleanedUrl) {
      const variantsData = await getPersonalisedVariantsForUrl(cleanedUrl, selectedLanguage, personalizationId);

      if (!variantsData) {
        console.error("No data returned from getPersonalisedVariantsForUrl");
        return;
      }

      const validVariantsData = variantsData.filter(variantData => Array.isArray(variantData[2]));

      const variantsWithEditFlags = validVariantsData.map((variantData, index) => {
        const [originalText, , variantsArray, language] = variantData;
        return [originalText, variantsArray[0] || { ID: 999, Text: '' }, language];
      });

      setVariants(variantsWithEditFlags);
      setPageIndex(0);
    }
  };

  const handleVariantSave = (originalIndex, newText) => {
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      newVariants[originalIndex][1].tempText = newText;
      newVariants[originalIndex][1].edited = true;
      return newVariants;
    });
  };

  const handleSaveEdits = async (originalIndex) => {
    const [originalText, variant, language] = variants[originalIndex];
    if (variant.tempText !== undefined) {
      const dataToSend = [{
        Url: cleanedUrl,
        Language: language,
        Text: originalText,
        Variants: [{
          ID: variant.ID,
          Text: variant.tempText
        }]
      }];
  
      try {
        await updatePersonalizedVariantOnServer(dataToSend, personalizationId);
        console.log("Personalized variant updated successfully!");
        setVariants(prevVariants => {
          const newVariants = [...prevVariants];
          newVariants[originalIndex][1].Text = newVariants[originalIndex][1].tempText;
          delete newVariants[originalIndex][1].tempText;
          delete newVariants[originalIndex][1].edited;
          return newVariants;
        });
      } catch (error) {
        console.error("Error updating personalized variant:", error);
      }
    } else {
      console.log("No changes to save for this personalized variant.");
    }
  };

  const handleNextPage = () => {
    setPageIndex(pageIndex + 1);
  };

  const handlePreviousPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const pageVariants = variants.slice(pageIndex * PAGE_SIZE, (pageIndex + 1) * PAGE_SIZE);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width="80%"
      title="Personalized Variant Editor (Please wait at least 10 seconds to load)"
      footer={null}
    >
      <div data-seatextdont>
        <div style={{ padding: '20px' }}>
          <List>
            {pageVariants.map(([originalText, variant, language], originalIndex) => (
              <List.Item key={originalIndex} style={{ width: '100%' }}>
                <div style={{ backgroundColor: 'white', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '20px', marginBottom: '5px', width: '100%' }}>
                  <Title level={4} style={{ borderBottom: '2px solid #E8E8E8', paddingBottom: '10px', marginBottom: '20px' }}>{originalText}</Title>
                  <div style={{ marginBottom: '16px', width: '100%' }}>
                    <EditableTextArea
                      initialValue={variant.tempText || variant.Text}
                      onSave={(newText) => handleVariantSave(originalIndex, newText)}
                    />
                    <Button
                      icon={<SaveOutlined />}
                      onClick={() => handleSaveEdits(originalIndex)}
                      disabled={!variant.edited}
                      style={{ marginTop: '10px' }}
                    >
                      Save Variant
                    </Button>
                  </div>
                </div>
              </List.Item>
            ))}

            {/* Pagination Controls */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
              <Button
                onClick={handlePreviousPage}
                disabled={pageIndex === 0}
                icon={<LeftOutlined />}
              >
                Previous Page
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={pageIndex * PAGE_SIZE + PAGE_SIZE >= variants.length}
                icon={<RightOutlined />}
              >
                Next Page
              </Button>
            </div>
          </List>
        </div>
      </div>
    </Modal>
  );
}