import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Switch, Select, Form, message } from 'antd';
import { sendFeatureStatusToServer2, fetchSettingsFromServer, getPageListFromSettings } from './utils';

const { Text } = Typography;
const { Option } = Select;

function ConversionSettingsPage(props) {
  const defaultSettings = {
    AutoDetectConversion: true,
    ManualConversion: false,
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [urls, setUrls] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);

  // Move loadSettings outside of useEffect to make it accessible
  const loadSettings = async (url) => { 
    try {
      const serverSettings = await fetchSettingsFromServer(url); 
      
      // Add extensive console logging
      console.log('Raw server settings:', serverSettings);
      console.log('Default settings:', defaultSettings);
      
      if (serverSettings) {
        const mergedSettings = { ...defaultSettings, ...serverSettings };
        console.log('Merged settings:', mergedSettings);
        setSettings(mergedSettings);
      } else {
        console.log('No server settings received, using default settings');
        setSettings(defaultSettings);
      }
    } catch (error) {
      console.error('Failed to fetch settings:', error);
      message.error('Failed to load settings.');
    }
  };

  useEffect(() => {
    let initialUrl = props.context?.url || '';
  
    const fetchPageList = async () => {
      let pageList = await getPageListFromSettings();
      if (pageList && pageList.length > 0) {
        pageList.sort((a, b) => {
          if (a.includes("Whole website")) return -1;
          if (b.includes("Whole website")) return 1;
          return a.length - b.length;
        });
  
        const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
        if (wholeWebsiteUrls.length > 1) {
          const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
          pageList = pageList.filter(url => !url.includes("Whole website"));
          pageList.unshift(firstWholeWebsiteUrl);
        }
  
        setUrls(pageList);
        const wholeSiteUrl = pageList.find(url => url.includes("Whole website"));
        const urlToSelect = initialUrl || wholeSiteUrl || pageList[0];
        
        setSelectedUrl(urlToSelect);
        setShowWholeWebsiteWarning(!!wholeSiteUrl);
  
        // Load settings for the selected URL
        async function loadSettings() {
          try {
            const serverSettings = await fetchSettingsFromServer(urlToSelect);
            console.log('Server settings received:', serverSettings);
            
            if (serverSettings) {
              const updatedSettings = { 
                ...defaultSettings, 
                ...serverSettings 
              };
              console.log('Updated settings:', updatedSettings);
              setSettings(updatedSettings);
            } else {
              console.log('No server settings, using default settings');
              setSettings(defaultSettings);
            }
          } catch (error) {
            console.error('Failed to fetch settings:', error);
            message.error('Failed to load settings.');
            // Fallback to default settings
            setSettings(defaultSettings);
          }
        }
  
        loadSettings();
      }
    };
  
    fetchPageList();
  }, [props.context?.url]);
  
  // Update handleUrlChange to match the same pattern
  const handleUrlChange = (value) => {
    setSelectedUrl(value);
    setShowWholeWebsiteWarning(value.includes("Whole website"));
  
    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(value);
        console.log('Server settings received:', serverSettings);
        
        if (serverSettings) {
          const updatedSettings = { 
            ...defaultSettings, 
            ...serverSettings 
          };
          console.log('Updated settings:', updatedSettings);
          setSettings(updatedSettings);
        } else {
          console.log('No server settings, using default settings');
          setSettings(defaultSettings);
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
        message.error('Failed to load settings.');
        // Fallback to default settings
        setSettings(defaultSettings);
      }
    }
  
    loadSettings();
  };

  const handleSettingChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    
    // Add extensive logging
    console.log('Original settings:', settings);
    console.log('Updated settings:', updatedSettings);
    console.log('Key changed:', key);
    console.log('New value:', value);
  
    setSettings(updatedSettings);
  
    const payload = {
      url: selectedUrl,
      data: updatedSettings, // Try using 'data' instead of 'settings'
    };
  
    console.log('Payload being sent:', payload);
  
    sendFeatureStatusToServer2(payload).catch(error => {
      console.error('Failed to update settings:', error);
      message.error('Failed to update settings.');
    });
  };



  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ fontSize: '28px', marginBottom: '30px' }}>Configure what Seatext AI should count as conversion</h1>

      <Form layout="vertical" style={{ marginBottom: '20px', width: '100%' }}>
        <Select
          showSearch
          style={{ width: '100%' }}
          placeholder="Select a URL for conversion settings"
          value={selectedUrl}
          onChange={handleUrlChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {urls.map(url => (
            <Option value={url} key={url}>{url}</Option>
          ))}
        </Select>
      </Form>

      {showWholeWebsiteWarning && (
        <div style={{ margin: '10px 0', padding: '10px', backgroundColor: '#f8f8f8', border: '1px solid #e8e8e8' }}>
          Settings will apply to all pages on your website, including future pages.
        </div>
      )}

      <Row gutter={[16, 24]}>
        <Col span={24}>
        <Card style={{ backgroundColor: '#f8f8f8', border: '1px solid #d9d9d9', marginBottom: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <Switch
                checked={settings.AutoDetectConversion}
                onChange={(checked) => handleSettingChange('AutoDetectConversion', checked)}
                style={{ marginRight: '10px' }}
              />
              <Text style={{ fontSize: '20px', fontWeight: 'bold' }}>Automatic AI Conversion Detection</Text>
            </div>
            <Text style={{ fontSize: '16px', display: 'block', marginLeft: '50px' }}>
              Our AI system automatically identifies various conversion events across your website:
              <br/><br/>• For e-commerce platforms (Shopify, WooCommerce, or similar), AI detects important actions like:
              <br/>  - Add to cart button clicks
              <br/>  - Checkout process initiation
              <br/>  - Successful purchase completions
              <br/>  - Wishlist additions
              <br/><br/>• For general websites, AI considers engagement patterns such as:
              <br/>  - Visitors spending more than 40 seconds actively reading content
              <br/>  - Users scrolling through 80% of your page length
              <br/>  - Meaningful navigation patterns (visiting multiple pages in a session)
              <br/>  - Form submissions and sign-ups
              <br/><br/>The AI adapts to your website's specific structure and visitor behavior patterns to identify what constitutes a meaningful conversion for your particular case.
            </Text>
          </Card>

          <Card style={{ backgroundColor: '#f8f8f8', border: '1px solid #d9d9d9' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <Switch
                checked={settings.ManualConversion}
                onChange={(checked) => handleSettingChange('ManualConversion', checked)}
                style={{ marginRight: '10px' }}
              />
              <Text style={{ fontSize: '20px', fontWeight: 'bold' }}>Manual Conversion Tracking</Text>
            </div>
            
            <div style={{ marginLeft: '50px' }}>
              <Text style={{ fontSize: '16px', display: 'block', marginBottom: '20px' }}>
                Choose from three methods to manually mark conversions, depending on your technical expertise:
              </Text>

              <div style={{ marginBottom: '25px' }}>
                <Text strong>1. For Non-Developers (Easy Website Editor Method)</Text>
                <Text style={{ display: 'block', margin: '10px 0' }}>
                  Simply add the word "seatextconversion" to any clickable text in your website editor (WordPress, Wix, Shopify, etc.). 
                  The word will be automatically removed before displaying to visitors, but Seatext will track clicks on this element as conversions.
                  You can add this to any clickable element - buttons, links, or text.
                </Text>
                <div style={{ background: '#fff', padding: '15px', marginTop: '5px' }}>
                  <Text>What you add in editor:</Text>
                  <pre style={{ background: '#f7f7f7', padding: '10px', marginTop: '5px' }}>
                    {'<button>Buy Now seatextconversion</button>'}
                  </pre>
                  <Text style={{ marginTop: '10px' }}>What visitors see:</Text>
                  <pre style={{ background: '#f7f7f7', padding: '10px', marginTop: '5px' }}>
                    {'<button>Buy Now</button>'}
                  </pre>
                </div>
              </div>

              <div style={{ marginBottom: '25px' }}>
                <Text strong>2. For Developers (HTML Data Attribute Method)</Text>
                <Text style={{ display: 'block', margin: '10px 0' }}>
                  Add the 'data-seatextconversion' attribute to any HTML element you want to track. 
                  This is ideal for tracking specific buttons, forms, or any other interactive elements.
                  The attribute works with any HTML element and can be added to dynamic content as well.
                </Text>
                <div style={{ background: '#fff', padding: '15px', marginTop: '5px' }}>
                  <Text>Button example:</Text>
                  <pre style={{ background: '#f7f7f7', padding: '10px', marginTop: '5px' }}>
                    {'// Original button:\n'}
                    {'<button class="purchase-btn">Buy Now</button>\n\n'}
                    {'// Button with conversion tracking:\n'}
                    {'<button class="purchase-btn" data-seatextconversion>Buy Now</button>'}
                  </pre>
                  
                  <Text style={{ marginTop: '15px' }}>Form example:</Text>
                  <pre style={{ background: '#f7f7f7', padding: '10px', marginTop: '5px' }}>
                    {'// Original form:\n'}
                    {'<form class="signup-form">\n'}
                    {'  <input type="email" placeholder="Enter email">\n'}
                    {'  <button type="submit">Sign Up</button>\n'}
                    {'</form>\n\n'}
                    {'// Form with conversion tracking:\n'}
                    {'<form class="signup-form" data-seatextconversion>\n'}
                    {'  <input type="email" placeholder="Enter email">\n'}
                    {'  <button type="submit">Sign Up</button>\n'}
                    {'</form>'}
                  </pre>
                  
                  <Text style={{ marginTop: '15px' }}>React component example:</Text>
                  <pre style={{ background: '#f7f7f7', padding: '10px', marginTop: '5px' }}>
                    {'// Original component:\n'}
                    {'<Button onClick={handlePurchase}>Complete Purchase</Button>\n\n'}
                    {'// Component with conversion tracking:\n'}
                    {'<Button \n'}
                    {'  onClick={handlePurchase}\n'}
                    {'  data-seatextconversion\n'}
                    {'>'}
                    {'  Complete Purchase\n'}
                    {'</Button>'}
                  </pre>
                </div>
              </div>

              <div>
                <Text strong>3. For Advanced Implementation (JavaScript Event Method)</Text>
                <Text style={{ display: 'block', margin: '10px 0' }}>
                  Trigger the conversion event programmatically using JavaScript. This method provides the most flexibility,
                  allowing you to track conversions based on complex user interactions, custom events, or specific conditions.
                  Ideal for single-page applications or custom tracking scenarios.
                </Text>
                <pre style={{ background: '#fff', padding: '10px', marginTop: '5px' }}>
                  {'// Call this function whenever you want to track a conversion\n'}
                  {'window.seatextConversion()\n\n'}
                  {'// Example usage:\n'}
                  {'document.getElementById("checkout-button").addEventListener("click", () => {\n'}
                  {'  // Your checkout logic\n'}
                  {'  window.seatextConversion()\n'}
                  {'});'}
                </pre>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ConversionSettingsPage;