import React, { useState, useEffect } from 'react';
import { Typography, Card, Button, Input, Form, Collapse, Avatar, Switch, Tooltip, message, Modal, Upload, Table, Spin  } from 'antd';
import { PlusOutlined, UserOutlined, InfoCircleOutlined, CopyOutlined, EditOutlined, DeleteOutlined, UploadOutlined, DownloadOutlined, LinkOutlined, RobotOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { fetchSettingsFromServer, loadCustomerProfilesFromServer, sendCustomerProfilesToServer, getDomainFromCookies, deletePersonalisedVariantsForUrl } from './utils';
import AIPersonalizationFeatures from './aipersonalisationfeatures';
import PersonalizedVariantEditor from './PersonalizedVariantEditor';
import LottieAnimation from './LottieAnimation';
import { sendProspectsTableToServer } from './utils';
import VideoIntro from './VideoIntro'; // Import the new VideoIntro component

const { Title } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

function AISalesPersonalisation(props) {
  const [prospects, setProspects] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [domain, setDomain] = useState('');
  const [editorVisible, setEditorVisible] = useState(false);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [prospectToDelete, setProspectToDelete] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasTargetUrl, setHasTargetUrl] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentProspect, setCurrentProspect] = useState(null);
  const [addProspectModalVisible, setAddProspectModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [urlInputValue, setUrlInputValue] = useState(`https://${getDomainFromCookies()}/`);
  const [isLoadingVariants, setIsLoadingVariants] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
const [countdown, setCountdown] = useState(0);

// Add this useEffect for the countdown timer
useEffect(() => {
  let timer;
  if (countdown > 0) {
    timer = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);
  }
  return () => clearInterval(timer);
}, [countdown]);

  useEffect(() => {



    fetchSettingsFromServer(props.context?.url)
      .catch(error => console.error('Failed to fetch settings:', error));

    loadCustomerProfilesFromServer()
      .then(response => {
        if (response.success) {
          setProspects(response.data);
          const initialHasTargetUrl = {};
          response.data.forEach(prospect => {
            initialHasTargetUrl[prospect.id] = !!prospect.focusUrl;
          });
          setHasTargetUrl(initialHasTargetUrl);
        } else {
          message.error('Failed to load customer profiles: ' + response.error);
        }
      })
      .catch(error => {
        console.error('Failed to load customer profiles:', error);
        message.error('Failed to load customer profiles. Please try again.');
      });

    const domainFromCookies = getDomainFromCookies();
    if (domainFromCookies) {
      setDomain(domainFromCookies);
    }
  }, [props.context?.url]);

 


// Helper function to generate a personalization ID
const generatePersonalizationId = (name, familyName) => {
  // Check if both name and family name exist and are not empty strings after trimming
  if (name?.trim() && familyName?.trim()) {
    const cleanName = (name + familyName)
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9]/g, '');
    return cleanName || generateRandomIdentifier();
  }
  return generateRandomIdentifier();
};

// Improved random identifier generator
const generateRandomIdentifier = () => {
  return `id${Math.random().toString(36).substring(2, 8)}`;
};


// Helper function to generate a personalized URL
const generatePersonalizedUrl = (baseUrl, personalizationId) => {
  if (!baseUrl || !personalizationId) return baseUrl;
  
  try {
    // Ensure the URL has a protocol
    let urlToProcess = baseUrl;
    if (!urlToProcess.startsWith('http://') && !urlToProcess.startsWith('https://')) {
      urlToProcess = 'https://' + urlToProcess;
    }
    
    const url = new URL(urlToProcess);
    // Remove any existing seatext parameter
    url.searchParams.delete('seatext');
    // Add the new seatext parameter
    url.searchParams.set('seatext', personalizationId);
    return url.toString();
  } catch (error) {
    console.error('Invalid base URL:', baseUrl);
    // If URL parsing fails, try a basic string manipulation approach
    const cleanBaseUrl = baseUrl.replace(/([?&])seatext=[^&]*(&|$)/, '$1');
    const separator = cleanBaseUrl.includes('?') ? '&' : '?';
    return `${cleanBaseUrl}${separator}seatext=${personalizationId}`;
  }
};

  const columns = [
    {
      title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <Tooltip title={`${record.name ?? ''} ${record.familyName ?? ''}`}>
        <span>{`${record.name ?? ''} ${record.familyName ?? ''}`?.slice(0, 20)}</span>
      </Tooltip>
    ),
    width: '15%',
  },
  {
    title: 'Company',
    dataIndex: 'corporation',
    key: 'corporation',
    render: (text) => (
      <Tooltip title={text ?? ''}>
        <span>{text?.slice(0, 20) ?? ''}</span>
      </Tooltip>
    ),
    width: '15%',
  },

  {
    title: 'AI Prompt',
    dataIndex: 'personalizationData',
    key: 'personalizationData',
    width: '50%',
    render: (text) => (
      <div style={{
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical',
        lineHeight: '1.5em',
        maxHeight: '6em',
        whiteSpace: 'pre-line',
        wordWrap: 'break-word'
      }}>
        {text ?? ''}
      </div>
    ),
  },

  {
    title: 'Personalized URL',
    dataIndex: 'focusUrl',
    key: 'focusUrl',
    render: (text, record) => {
      // Generate the proper URL with protocol
      let baseUrl = record.baseUrl;
      if (!baseUrl.startsWith('http://') && !baseUrl.startsWith('https://')) {
        baseUrl = 'https://' + baseUrl;
      }
      
      const fullUrl = generatePersonalizedUrl(baseUrl, record.personalizationId);
      
      return (
        <Tooltip title={fullUrl}>
          <a 
            href={fullUrl} 
            target="_blank" 
            rel="noopener noreferrer"
            onClick={(e) => {
              // Prevent default only if URL is invalid
              if (!fullUrl.includes('://')) {
                e.preventDefault();
                message.error('Invalid URL format');
              }
            }}
          >
            {baseUrl.replace(/^https?:\/\//, '').slice(0, 30)}...?seatext={record.personalizationId}
          </a>
        </Tooltip>
      );
    },
  },



  {
    title: 'Allow AI Use Name',
    dataIndex: 'allowAIUseName',
    key: 'allowAIUseName',
    render: (text, record) => <Switch size="small" checked={record.allowAIUseName} onChange={(checked) => handleSwitchChange(record.id, 'allowAIUseName', checked)} />,
    width: '10%',
  },
  {
    title: 'Allow AI Use Company Name',
    dataIndex: 'allowAIUseCompany',
    key: 'allowAIUseCompany',
    render: (text, record) => <Switch size="small" checked={record.allowAIUseCompany} onChange={(checked) => handleSwitchChange(record.id, 'allowAIUseCompany', checked)} />,
    width: '15%',
  },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '90px' }}>
          <Tooltip title="Edit prospect information">
            <Button 
              icon={<EditOutlined />} 
              type="text" 
              style={{ background: 'transparent', border: 'none', padding: '4px' }}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete prospect and associated AI texts">
            <Button 
              icon={<DeleteOutlined />} 
              type="text" 
              style={{ background: 'transparent', border: 'none', padding: '4px' }}
              onClick={() => showDeleteConfirm(record)}
            />
          </Tooltip>
          <Tooltip title="Edit AI-generated text variants">
            <Button 
              icon={<RobotOutlined />} 
              type="text" 
              style={{ background: 'transparent', border: 'none', padding: '4px' }}
              onClick={() => openEditor(record)}
            />
          </Tooltip>
        </div>
      ),
      width: '120px',
    },
  ];

  const handleSwitchChange = (id, field, value) => {
    setProspects(prevProspects =>
      prevProspects.map(p => p.id === id ? { ...p, [field]: value } : p)
    );
  };


  // Custom CSV parser
// Improved parseCSV function with more robust cleaning
const parseCSV = (csvText) => {
  const lines = csvText.split('\n');
  const headers = lines[0].split(',').map(cleanField);
  
  return lines.slice(1).map(line => {
    const values = line.split(',').map(cleanField);
    return headers.reduce((obj, header, index) => {
      obj[header] = values[index] || '';
      return obj;
    }, {});
  }).filter(row => Object.values(row).some(value => value !== ''));
};

// Helper function to clean fields
const cleanField = (field) => {
  return field.trim()
    .replace(/^["']+|["']+$/g, '')  // Remove leading/trailing quotes
    .replace(/^["']{2}|["']{2}$/g, '"')  // Handle escaped quotes
    .replace(/\\"/g, '"');  // Unescape quotes
};

const handleCSVUpload = (file) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      if (results.data && results.data.length > 0) {
        const newProspects = results.data.map((row, index) => {
          const name = row['First Name']?.trim() || '';
          const familyName = row['Last Name']?.trim() || '';
          const corporation = row['Company']?.trim() || '';
          const personalizationId = generatePersonalizationId(name, familyName);
          const baseUrl = row['Base URL'] || `https://${domain}/`;
          
          return {
            id: Date.now() + index,
            name,
            familyName,
            corporation,
            baseUrl: baseUrl,
            focusUrl: generatePersonalizedUrl(baseUrl, personalizationId),
            personalizationId,
            personalizationData: row['Personalization Data'] || '',
            // Only allow AI use if corresponding fields are not empty
            allowAIUseName: (name && familyName) ? 
              String(row['Allow AI Use Name']).toLowerCase() === 'true' : false,
            allowAIUseCompany: corporation ? 
              String(row['Allow AI Use Company']).toLowerCase() === 'true' : false
          };
        });

        setProspects(prevProspects => {
          const updatedProspects = [...prevProspects];
          newProspects.forEach(newProspect => {
            const existingIndex = updatedProspects.findIndex(p => 
              p.personalizationId === newProspect.personalizationId
            );
            if (existingIndex !== -1) {
              updatedProspects[existingIndex] = { ...updatedProspects[existingIndex], ...newProspect };
            } else {
              updatedProspects.push(newProspect);
            }
          });
          message.success(`Successfully processed ${newProspects.length} prospects`);
          return updatedProspects;
        });
      }
    },
    error: (error) => {
      console.error('Error parsing CSV:', error);
      message.error('Failed to parse CSV file. Please check the format.');
    }
  });
};

// First, create a reusable form component
const ProspectForm = ({ initialValues, onFinish, form }) => {
  const [baseUrl, setBaseUrl] = useState(initialValues?.baseUrl || '');
  
  // Watch all relevant fields for changes
  const nameValue = Form.useWatch('name', form);
  const familyNameValue = Form.useWatch('familyName', form);
  const corporationValue = Form.useWatch('corporation', form);

  useEffect(() => {
    // Check if name fields are filled
    const name = nameValue?.trim();
    const familyName = familyNameValue?.trim();
    
    // Only update the switch if both fields are empty
    if (!name || !familyName) {
      form.setFieldsValue({ allowAIUseName: false });
    }
  }, [nameValue, familyNameValue, form]);

  useEffect(() => {
    // Check if corporation field is filled
    const corporation = corporationValue?.trim();
    
    // Only update the switch if field is empty
    if (!corporation) {
      form.setFieldsValue({ allowAIUseCompany: false });
    }
  }, [corporationValue, form]);

  return (
    <Card style={{ 
      width: '1000px',
      margin: '0 auto',
      boxShadow: 'none', // Remove card shadow inside modal
      border: 'none' // Remove card border inside modal
    }}>
      <Form
        form={form}
        initialValues={{...initialValues, focusUrl: baseUrl}}
        onFinish={(values) => {
          const name = values.name?.trim();
          const familyName = values.familyName?.trim();
          const corporation = values.corporation?.trim();
          
          const personalizationId = generatePersonalizationId(name, familyName);
          const fullUrl = generatePersonalizedUrl(values.focusUrl, personalizationId);
          
          onFinish({
            ...values,
            name: name || '',
            familyName: familyName || '',
            corporation: corporation || '',
            baseUrl: values.focusUrl,
            focusUrl: fullUrl,
            personalizationId,
            allowAIUseName: (name && familyName) ? values.allowAIUseName : false,
            allowAIUseCompany: corporation ? values.allowAIUseCompany : false
          });
        }}
        layout="vertical"
      >
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '24px' }}>
          <Form.Item 
            name="name" 
            label="First Name"
          >
            <Input maxLength={255} />
          </Form.Item>
          
          <Form.Item 
            name="familyName" 
            label="Last Name"
          >
            <Input maxLength={255} />
          </Form.Item>
          
          <Form.Item 
            name="corporation" 
            label="Company"
          >
            <Input maxLength={255} />
          </Form.Item>
        </div>
        
        <Form.Item
          name="focusUrl"
          label="URL of page you want to personalize"
          rules={[
            { required: true, message: 'Please input the URL!' },
            {
              validator: (_, value) => {
                if (!isValidUrl(value)) {
                  return Promise.reject('Please enter a valid website address');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input 
            maxLength={2048}
            placeholder="example.com"
            onChange={(e) => setBaseUrl(e.target.value)}
          />
        </Form.Item>

        <Form.Item 
          name="personalizationData" 
          label={
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Personalization Data</span>
                <Tooltip title="Enter detailed information about the prospect to help AI personalize content effectively. Include their interests, challenges, goals, and any relevant background information.">
                  <InfoCircleOutlined style={{ marginLeft: '8px' }} />
                </Tooltip>
              </div>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => 
                  prevValues?.personalizationData !== currentValues?.personalizationData
                }
              >
                {({ getFieldValue }) => {
                  const currentLength = getFieldValue('personalizationData')?.length || 0;
                  return (
                    <span style={{ 
                      fontSize: '12px', 
                      color: currentLength > 900000 ? '#ff4d4f' : '#8c8c8c' 
                    }}>
                      {currentLength.toLocaleString()} / 1,000,000
                    </span>
                  );
                }}
              </Form.Item>
            </div>
          }
          rules={[
            { required: true, message: 'Please input personalization data!' },
            { validator: validatePersonalizationData }
          ]}
        >
          <Input.TextArea
            style={{
              height: '400px',
              fontSize: '14px',
              lineHeight: '1.6'
            }}
            showCount={{
              formatter: ({ count }) => `${count.toLocaleString()} characters`
            }}
            placeholder="Enter detailed information about the prospect to help AI personalize content effectively. We recommend:

- Their current problems and challenges
- Key differences in their products and services
- Goals and objectives they are trying to achieve
- Industry-specific pain points
- Previous interactions or discussions
- Relevant background information
- Specific interests or focus areas
- Direct instructions to AI on how to rewrite a website"
          />
        </Form.Item>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
          <Form.Item 
            name="allowAIUseName" 
            valuePropName="checked" 
            label="Allow AI to use name"
          >
            <Switch 
              disabled={!(form.getFieldValue('name')?.trim() && form.getFieldValue('familyName')?.trim())}
            />
          </Form.Item>

          <Form.Item 
            name="allowAIUseCompany" 
            valuePropName="checked" 
            label="Allow AI to use company name"
          >
            <Switch 
              disabled={!form.getFieldValue('corporation')?.trim()}
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {initialValues?.id ? 'Save Changes' : 'Add Prospect'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};


// Add validation for personalization data length
const validatePersonalizationData = (rule, value) => {
  if (!value) {
    return Promise.reject('Please input personalization data!');
  }
  
  // Most databases can handle text fields up to 16MB or more
  // But we'll set a reasonable limit for UI performance
  const maxLength = 1000000; // 1 million characters
  
  if (value.length > maxLength) {
    return Promise.reject(`Personalization data exceeds maximum length of ${maxLength} characters`);
  }
  
  return Promise.resolve();
};

const [editForm] = Form.useForm();
const [addForm] = Form.useForm();

const modalStyle = {
  minWidth: '1060px', // Slightly wider than form to account for padding
  top: '20px' // Position from top of screen
};




const handleCSVExport = () => {
  // Helper function to escape special characters in CSV fields
  const escapeCSVField = (field) => {
    if (field === null || field === undefined) return ''; 

    const stringField = String(field);
    const shouldEscape = stringField.includes('"') || stringField.includes(',') || stringField.includes('\n') || stringField.includes('\r');

    if (shouldEscape) {
      return `"${stringField.replace(/"/g, '""')}"`;
    }
    return stringField;
  };

  const headers = [
    'First Name', 'Last Name', 'Company',  'Personalized URL',
    'Personalization ID', 'Personalization Data', 'Allow AI Use Name', 'Allow AI Use Company'
  ];


  const csvRows = [
    headers.map(escapeCSVField).join(','),
    ...prospects.map((p) => {
      const fullPersonalizedUrl = generatePersonalizedUrl(p.baseUrl, p.personalizationId);
      return [
        p.name,
        p.familyName,
        p.corporation,
       
        fullPersonalizedUrl, // Use the properly generated personalized URL
        p.personalizationId,
        p.personalizationData,
        p.allowAIUseName,
        p.allowAIUseCompany,
      ].map(escapeCSVField).join(',');
    }),
  ].join('\r\n');

  try {
    const blob = new Blob([`\uFEFF${csvRows}`], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'prospects.csv';
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    // Delay revoking the object URL to allow the download to start
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link); // Remove the link after revocation
    }, 500); // Adjust delay as needed (start with a larger value like 500ms)

  } catch (error) {
    console.error("Error generating or downloading CSV:", error);
    message.error("An error occurred while downloading the CSV. Please try again.");
  }
};

  // Updated isValidUrl function to handle missing schema
  const isValidUrl = (url) => {
    try {
        // Attempt to parse URL. If no schema, add https://
        new URL(url.startsWith('http') ? url : 'https://' + url);
      return true;
    } catch (error) {
      return false;
    }
  };
  
  // Helper function to remove seatext parameter from URL
  const removeSeatextFromUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      parsedUrl.searchParams.delete('seatext');
      return parsedUrl.toString();
    } catch (error) {
      return url.split('?')[0];
    }
  };
  
  // Helper function to ensure seatext parameter is in URL
// Simplified URL parameter addition
const ensureSeatextInUrl = (url, personalizationId) => {
  if (!url) return url;
  const baseUrl = removeSeatextFromUrl(url);
  const separator = baseUrl.includes('?') ? '&' : '?';
  return `${baseUrl}${separator}seatext=${personalizationId}`;
};





  const handleEdit = (record) => {
    setCurrentProspect(record);
    setUrlInputValue(removeSeatextFromUrl(record.focusUrl)); // Pre-fill URL input
    setEditModalVisible(true);
  };

// Update the handleEditSave function
const handleEditSave = (values) => {
  const personalizationId = generatePersonalizationId(values.name, values.familyName);
  const baseUrl = values.focusUrl; // Use the entered URL directly
  const fullUrl = ensureSeatextInUrl(baseUrl, personalizationId);
  
  const updatedProspect = {
    ...currentProspect,
    ...values,
    personalizationId,
    focusUrl: fullUrl
  };

  setProspects(prevProspects =>
    prevProspects.map(p => p.id === currentProspect.id ? updatedProspect : p)
  );
  
  setEditModalVisible(false);
  editForm.resetFields();
};

  const showDeleteConfirm = (prospect) => {
    setProspectToDelete(prospect);
    setDeleteModalVisible(true);
  };

// Helper function to normalize URL format
const normalizeUrl = (url) => {
  try {
    // Remove any trailing slashes and query parameters
    return url.split('?')[0].replace(/\/+$/, '');
  } catch (error) {
    console.error('Error normalizing URL:', error);
    return url;
  }
};




// Modified delete handler function
const handleDeleteConfirm = () => {
  if (prospectToDelete) {
    // Remove the prospect from the UI immediately
    setProspects(prospects.filter(p => p.id !== prospectToDelete.id));
    setDeleteModalVisible(false);
    message.success('Profile deleted successfully');

    // Trigger the server deletion in the background
    deletePersonalizedVariants(prospectToDelete).catch(error => {
      console.error('Failed to delete profile on server:', error);
      message.error('Failed to delete profile on server. The changes may not persist.');
    });
  }
};


const deletePersonalizedVariants = async (prospect) => {
  try {
    // Extract and normalize the URL from the prospect's focusUrl
    const url = normalizeUrl(prospect.focusUrl || '');
    
    console.log('Deleting variants with data:', {
      url: url,
      personalizationId: prospect.personalizationId,
      originalUrl: prospect.focusUrl
    });

    if (!url) {
      throw new Error('No valid URL found for deletion');
    }

    const result = await deletePersonalisedVariantsForUrl(
      url,
      'English',
      prospect.personalizationId
    );

    console.log('Delete request result:', result);
    return result;
  } catch (error) {
    console.error('Delete variants error:', {
      error: error.message,
      prospect: prospect
    });
    throw new Error(`Failed to delete AI texts: ${error.message}`);
  }
};

  const saveProspectProfile = (profile) => {
    if (isSaving || !profile.name || !profile.familyName) {
      message.error('Please enter both name and family name');
      return;
    }
  
    setIsSaving(true);
  
    const serverProfile = {
      profiles: [{
        "First Name": profile.name,
        "Last Name": profile.familyName,
        "Company": profile.corporation,
        "Base URL": profile.focusUrl.split('?')[0],
        "Personalization Data": profile.personalizationData,
        "Personalization ID": profile.personalizationId,
        "Allow AI Use Name": profile.allowAIUseName,
        "Allow AI Use Company": profile.allowAIUseCompany
      }]
    };
  
    sendCustomerProfilesToServer(serverProfile)
      .then(response => {
        if (response.success) {
          message.success('Profile saved successfully');
        } else {
          message.error('Failed to save profile: ' + response.error);
        }
      })
      .catch(error => {
        console.error('Failed to save profile:', error);
        message.error('Failed to save profile');
      })
      .finally(() => {
        setTimeout(() => setIsSaving(false), 6000);
      });
  };

  const batchProcessProspects = async () => {
    if (isProcessing) return;
    
    // Set processing state and countdown at the start
    setIsProcessing(true);
    setCountdown(60);
    
    const serverProfiles = {
      profiles: prospects.map(p => ({
        "First Name": p.name,
        "Last Name": p.familyName,
        "Company": p.corporation,
        "Base URL": p.focusUrl.split('?')[0],
        "Personalization Data": p.personalizationData,
        "Personalization ID": p.personalizationId,
        "Allow AI Use Name": p.allowAIUseName,
        "Allow AI Use Company": p.allowAIUseCompany
      }))
    };
  
    try {
      const result = await sendProspectsTableToServer(serverProfiles);
      if (result.success) {
        message.success(`Successfully processed ${prospects.length} prospects`);
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      message.error(`Failed to process prospects: ${error.message}`);
    }
  
    // Keep the button disabled for the full minute regardless of API success/failure
    setTimeout(() => {
      setIsProcessing(false);
      setCountdown(0); // Reset countdown when done
    }, 60000);
  };

// Modify your openEditor function:
const openEditor = (prospect) => {
  setSelectedProspect(prospect);
  setEditorVisible(true);
  setIsLoadingVariants(true);
  
  // Simulate loading time (remove this in production and use real loading state)
  setTimeout(() => {
    setIsLoadingVariants(false);
  }, 10000);
};

  const handleAddProspect = () => {
    setAddProspectModalVisible(true);
  };

  const handleAddProspectSave = (values) => {
    const personalizationId = generatePersonalizationId(values.name, values.familyName);
    const baseUrl = removeSeatextFromUrl(values.focusUrl);
    const fullUrl = ensureSeatextInUrl(baseUrl, personalizationId);
    
    const newProspect = {
      id: Date.now(),
      ...values,
      personalizationId,
      focusUrl: ensureSeatextInUrl(values.focusUrl, personalizationId), // Use focusUrl from form
      allowAIUseName: values.allowAIUseName || false,
      allowAIUseCompany: values.allowAIUseCompany || false
    };
  
    setProspects(prevProspects => {
      const existingIndex = prevProspects.findIndex(p => p.personalizationId === personalizationId);
      if (existingIndex !== -1) {
        return prevProspects.map((p, index) => index === existingIndex ? { ...p, ...newProspect } : p);
      } else {
        return [...prevProspects, newProspect];
      }
    });
    
    setAddProspectModalVisible(false);
    addForm.resetFields();
  };


 

  return (
    <div style={{ width: '100%', margin: '0 auto', padding: '00px' }}>
     
     <div data-seatextdont style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
  <div style={{ width: '100%' }}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%' }}>
        <Card bordered={false} style={{ width: '100%', margin: '0' }}>
          <div className="ai-card-content" style={{
            marginBottom: '30px',
            marginTop: '0px',
            backgroundColor: '#f8f8f8',
            minHeight: '350px',
            padding: '0px',
            border: '0px solid #A9A9A9',
            width: '100%'
          }}>
            <div className="lottie-container" style={{ 
              float: 'left', 
              marginRight: '30px', 
              marginLeft: '10px', 
              marginTop: '50px', 
              width: '650px',
              maxWidth: '650px'  // Added to ensure consistent width
            }}>
              <VideoIntro 
                videoUrl="https://login.seatext.com/SEATEXTCLAY.mp4" 
                thumbnailMode={true} 
              />
            </div>
                  <div className="ai-text-content">
                    <div className="ai-title-container">
                      <h2 className="ai-title" style={{ fontSize: '22px', marginBottom: '10px', marginTop: '40px' }}>AI-Powered Website Personalization</h2>
                    </div>
                    <div className="ai-description" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
  SEATEXT AI-Powered Website Personalization customizes your website content for each prospect using custom AI prompts.<br /><br />
  
  You can use any data source to guide the AI. For example, import data from platforms like Clay.com or your CRM,
  then ask ChatGPT to create prompts for SEATEXT AI. This AI can rewrite your pages
  or generate personalized prompts manually for each prospect.<br /><br />

  Upload prompts in bulk formats so SEATEXT AI can personalize your website
  for thousands of prospects at scale.<br /><br />
  
  Leave specific instructions, like “rewrite this text like this,”
  to give the AI clear direction, or set general guidelines for it to follow to personalize.<br /><br />

  <p>Create tailored website experiences for each prospect by customizing content based on their profile.</p>

  <p><strong>Click on the video</strong> to learn how it works.</p>
</div>


                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div data-seatexttranslateoff data-seatextdont style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddProspect}
            style={{ backgroundColor: '##f8f8f8', borderColor: '##f8f8f8', height: '40px', fontSize: '14px' }}
          >
            Add New Prospect
          </Button>
<Upload
      accept=".csv"
      beforeUpload={(file) => {
        handleCSVUpload(file);
        return false;
      }}
    >
      <Button
        type="primary"
        icon={<UploadOutlined />}
        style={{ backgroundColor: '##f8f8f8', borderColor: '##f8f8f8', height: '40px', fontSize: '14px' }}
      >
        Upload CSV with Sales prospects and AI prompts
      </Button>
    </Upload>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleCSVExport}
            style={{ backgroundColor: '##f8f8f8', borderColor: '##f8f8f8', height: '40px', fontSize: '14px' }}
          >
            Download CSV with propects and personalized links
          </Button>
        </div>
        <div style={{ maxWidth: '300px' }}>
        <p style={{ fontSize: '10px', color: 'rgba(0, 0, 0, 0.45)', marginBottom: '0' }}>
            Download <a href="https://login.seatext.com/templateforseatext.csv">template CSV</a> to understand what format Seatext AI needs
          </p>
        </div>
      </div>

      <div data-seatextdont data-seatexttranslateoff>
  <Table
    columns={columns}
    dataSource={prospects}
    rowKey="id"
    style={{ marginTop: 20 }}
    pagination={{ pageSize: 10 }}
    scroll={{ x: true }}
    size="small"
  />
</div>

<Button
  type="primary"
  onClick={batchProcessProspects}
  style={{ marginTop: 20 }}
  disabled={isProcessing}
>
  {isProcessing ? (
    <span>
      <Spin size="small" style={{ marginRight: '8px' }} />
      Processing... {countdown}s
    </span>
  ) : (
    'Send all profiles to server and start personalization'
  )}
</Button>

      

      <Modal
        title="Confirm Deletion"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>Are you sure you want to delete all AI texts for this prospect?</p>
      </Modal>

 


      <Modal
      title="Edit Prospect"
      visible={editModalVisible}
      onCancel={() => {
        setEditModalVisible(false);
        editForm.resetFields();
        setUrlInputValue(`https://${domain}/`); // Reset URL input value
      }}
      footer={null}
      style={modalStyle}
  width={1060} // Match the minWidth
    >
      <ProspectForm
        form={editForm}
        initialValues={{...currentProspect, focusUrl: urlInputValue}}
        onFinish={handleEditSave}
      />
    </Modal>


<Modal
  title="Add New Prospect"
  visible={addProspectModalVisible}
  onCancel={() => {
    setAddProspectModalVisible(false);
    addForm.resetFields();
  }}
  footer={null}
  style={modalStyle}
  width={1060}

>
  <ProspectForm 
    form={addForm}
    initialValues={{
      allowAIUseName: false,
      allowAIUseCompany: false,
      focusUrl: `https://${domain}/`
    }}
    onFinish={handleAddProspectSave}
  />
</Modal>

<PersonalizedVariantEditor
  visible={editorVisible}
  onClose={() => {
    setEditorVisible(false);
    setIsLoadingVariants(false);  // Reset loading state when closing
  }}
  url={selectedProspect ? selectedProspect.focusUrl.split('?')[0] : ''}
  language="English"
  personalizationId={selectedProspect?.personalizationId}
>
  {isLoadingVariants && (
    <div style={{ 
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <Spin size="large" />
      <div style={{ marginTop: '20px', color: '#666' }}>
        Loading personalized variants...
      </div>
    </div>
  )}
</PersonalizedVariantEditor>

      
    </div>
  );
}


export default AISalesPersonalisation;