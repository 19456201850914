import React, { useState, useEffect } from 'react';
import { Typography, Card, Row, Col, Slider, Button, message, Switch, Alert, Form, Select } from 'antd';
import { sendRequest, sendFeatureStatusToServer2, fetchSettingsFromServer, getPageListFromSettings } from './utils';
import Cookies from 'js-cookie';
import { InfoCircleOutlined } from '@ant-design/icons';
import LottieAnimation from './LottieAnimation';

const { Title, Text } = Typography;
const { Option } = Select;

function SettingsPage2(props) {
  const defaultSettings = {
    AiABTestingEnabled: true,
    ABtestingCreativityLevel: 3,
    ABtestingVariantsTnumber: 5,
    ABtestingConfidenceLevel: 85,
    ABtestingEarlyElimination: 65,
    ABtestingBrandVoicePreservation: 5,
    ABtestingTrafficPercentageForVariants: 1,
    TitleTaglineEnhancementEnabled: true,
    WebsiteContentEnhancementEnabled: true,
    CTAsOptimizationEnabled: true,
    MenuItemsEnhancementEnabled: true,
    CustomerReviewsEnhancementEnabled: true,
    LegalDocumentationEnhancementEnabled: false,
    TechnicalDocumentationEnhancementEnabled: true,
    GeneralAIautoapproval: true,
    
  };

  const [settings, setSettings] = useState(defaultSettings);
  const [showAiABTestSwitch, setShowAiABTestSwitch] = useState(false);
  const [modifiedUrl, setModifiedUrl] = useState('');
  const [urls, setUrls] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [showWholeWebsiteWarning, setShowWholeWebsiteWarning] = useState(false);

  useEffect(() => {
    let initialUrl = props.context?.url || '';
    let isUrlFromCookies = false;

    if (!initialUrl) {
      const domainName = Cookies.get('domainName');
      if (domainName) {
        initialUrl = `${domainName} Whole website`;
        setShowAiABTestSwitch(true);
        isUrlFromCookies = true;
      }
    } else {
      setShowAiABTestSwitch(false);
    }

    setModifiedUrl(initialUrl);

    const fetchPageList = async () => {
      let pageList = await getPageListFromSettings();
      if (pageList && pageList.length > 0) {
        pageList.sort((a, b) => {
          if (a.includes("Whole website")) return -1;
          if (b.includes("Whole website")) return 1;
          return a.length - b.length;
        });

        const wholeWebsiteUrls = pageList.filter(url => url.includes("Whole website"));
        if (wholeWebsiteUrls.length > 1) {
          const firstWholeWebsiteUrl = wholeWebsiteUrls[0];
          pageList = pageList.filter(url => !url.includes("Whole website"));
          pageList.unshift(firstWholeWebsiteUrl);
        }

        setUrls(pageList);

        const wholeSiteUrl = pageList.find(url => url.includes("Whole website"));
        setSelectedUrl(wholeSiteUrl || pageList[0]);
        setShowWholeWebsiteWarning(!!wholeSiteUrl);
      }
    };

    fetchPageList();

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(initialUrl);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();

    return () => {
      if (isUrlFromCookies) {
      }
    };
  }, [props.context?.url]);

  const handleSettingChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);

    const payload = {
      url: selectedUrl || modifiedUrl,
      data: updatedSettings,
    };

    sendFeatureStatusToServer2(payload).catch(error => {
      console.error(error);
      message.error('Failed to update settings.');
    });
  };

  const handleUrlChange = (value) => {
    setSelectedUrl(value);
    setShowWholeWebsiteWarning(value.includes("Whole website"));
    setModifiedUrl(value);

    async function loadSettings() {
      try {
        const serverSettings = await fetchSettingsFromServer(value);
        if (serverSettings) {
          setSettings({ ...defaultSettings, ...serverSettings });
        }
      } catch (error) {
        console.error('Failed to fetch settings:', error);
      }
    }

    loadSettings();
  };

  const renderTitle = () => {
    const titleBase = "AI improves conversion rate settings";
    return modifiedUrl ? `${titleBase} for ${modifiedUrl}` : titleBase;
  };

  return (
    <div data-seatextdont  style={{ display: 'flex', justifyContent: 'center' }} >
<div style={{ width: '100%', minWidth: '1086px' }}>
  <div style={{ display: 'flex', justifyContent: 'center' }} >
    <div style={{ width: '100%' }}>
      <Card bordered={false} style={{ width: '100%' }}>
      <div className="ai-card-content" style={{ 
                marginBottom: '30px', 
                marginTop: '0px', 
                backgroundColor: '#f8f8f8', 
                minHeight: '350px', 
                padding: '20px',
                border: '1px solid #A9A9A9' // Changed to dark grey
              }}>
                <div className="lottie-container" style={{ float: 'left', marginRight: '20px',marginLeft: '20px', marginTop: '40px' }}>
                  <LottieAnimation
                    animationPath="https://lottie.host/d2fe082a-11e9-4cc7-bdcc-d7fec198dae1/rAp5IJKdyh.json"
                    width={500}
                    height={300}
                  />
                </div>
                <div className="ai-text-content">
                  <div className="ai-title-container">
                    <h2 className="ai-title" style={{ fontSize: '22px', marginBottom: '10px', marginTop: '40px' }}>General AI Model: improves any website sales and conversion rates</h2>
                  </div>
                  <div className="ai-description" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
                    SEATEXT AI grows your sales by generating over 1,000 text variants of your website, just like a professional copywriter. It then automatically A/B tests these variants like an expert online marketer, identifying which text variants actually increase your conversion rate and sales.
                    This AI model can work with any website and can have access to 90% of the text on your websites. You can define what text AI can or cannot fine-tune below.
                  </div>
                  <div>
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
    <Switch
      checked={settings.AiABTestingEnabled}
      onChange={(checked) => handleSettingChange('AiABTestingEnabled', checked)}
      checkedChildren="ON"
      unCheckedChildren="OFF"
      style={{ 
        marginRight: '10px', 
        minWidth: '80px'  // Adjust this value as needed
      }}
    />
    <span style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
      Activate AI Optimization / Deactivate AI Optimization
    </span>
  </div>
  
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
    <Switch
      checked={settings.GeneralAIautoapproval}
      onChange={(checked) => handleSettingChange('GeneralAIautoapproval', checked)}
      checkedChildren="ON"
      unCheckedChildren="OFF"
      style={{ 
        marginRight: '10px', 
        minWidth: '80px'  // Adjust this value as needed
      }}
    />
    <div>
      <span style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
        Automatically approve AI variants.
      </span>
      <br />
      <span style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
        Turn off if you wish to manually review variants before they are shown to your visitors. 
        <strong> WARNING! This can be time-consuming, as you will need to manually review each AI variant on every page, which may take hours for some clients.</strong>
      </span>
    </div>
  </div>
</div>
              <Form layout="vertical" style={{ marginTop: '20px', marginRight: '10px' }}>
                <Select
                  showSearch
                  style={{ width: '100%', marginBottom: '20px' }}
                  placeholder="Select a URL"
                  value={selectedUrl}
                  onChange={handleUrlChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {urls.map(url => (
                    <Option value={url} key={url}>{url}</Option>
                  ))}
                </Select>
                {showWholeWebsiteWarning && (
                  <div style={{ margin: '10px 0', padding: '10px', backgroundColor: '#f8f8f8', border: '1px solid #e8e8e8' }}>
                    You are editing settings for the entire website. Changes made here will affect all pages on your website,
                    including those added later. If you want to change settings for a particular URL and have specific settings for it,
                    please select a URL from the dropdown list above first.
                  </div>
                )}
              </Form>
                </div>



                
              </div>



  


          
              <div style={{ marginBottom: '30px' }}>

  <div style={{ display: 'flex', alignItems: 'flex-start', backgroundColor: '#f8f8f8', padding: '20px', borderRadius: '8px', marginBottom: '20px' }}>
    <img src="https://login.seatext.com/2.jpg" alt="SEA Text Tags" style={{ width: '380px', marginRight: '40px' }} />
    <div><h4 style={{ marginBottom: '20px', fontSize: '22px' }}>Control what text AI optimizes: How to define which text the AI can fine-tune and which it cannot?</h4>
      <h5 style={{ marginTop: '0' }}>Method 1:  Fine-tune Control with Special Tags by using `sea​textdont` and 'sea​textonly' inside your text</h5>
      <p>
      The best way to control AI optimization is by using special tags, we recommend using the `sea​textdont` and 'sea​textonly' special words inside your website editor as the most effective approach. You can find detailed information about these commands in our documentation at <a href="https://seatext.com/documentation-ai-scope" target="_blank" rel="noopener noreferrer">https://seatext.com/documentation-ai-scope</a>. This method offers the most precise control over AI's behavior. Using the section-based controls (Method 2) is less precise.
      </p> 
      <div style={{ height: '30px' }}></div>

      <h5>Method 2:  Choose Website Sections for AI Optimization (less precise)</h5>
  <p>
  If you can't use the special tags or prefer a quicker approach, you can use the sliders below to select specific sections of your website for AI optimization.
  </p>
    </div>
  </div>


</div>

  
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", gap: 10, width: '100%' }}>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexGrow: 0,
      flexShrink: 0,
      position: "relative",
      background: "#fff",
      width: '100%',
    }}
  >
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        height: 280,
        position: "relative",
        background: "#f5f5f5",
      }}
    >
      <div
        style={{
          width: '100%',
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f5f5f5",
        }}
      />
      <div
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: 28,
          top: 28,
          overflow: "hidden",
          borderRadius: 16,
          borderWidth: 1,
          borderColor: "#000",
        }}
      >
        <img
           src="https://login.seatext.com/titles-taglines.png"
          style={{
            width: 354,
            height: 224,
            position: "absolute",
            left: "-0.5px",
            top: "-0.5px",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.TitleTaglineEnhancementEnabled}
          onChange={(checked) => handleSettingChange('TitleTaglineEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 468,
          position: "absolute",
          left: 438,
          top: 32,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Titles, Taglines, and Short Phrases
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 60,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Enhance your website's titles, taglines, and short phrases with AI. This feature uses AI's
        contextual understanding to complement your content. Our AI tailors its suggestions based on
        your industry and the copy on your website. While AI can provide valuable insights, some
        suggestions may require manual review and adjustments to ensure optimal results.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        height: 280,
        position: "relative",
        background: "#fff",
      }}
    >
      <div
        style={{
          width: '100%',
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#fff",
        }}
      />
      <img
         src="https://login.seatext.com/website-content.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.WebsiteContentEnhancementEnabled}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          onChange={(checked) => handleSettingChange('WebsiteContentEnhancementEnabled', checked)}
           
           disabled={false}  
    
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Website Content Enhancement
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 56,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Enhance your website content using AI to boost user engagement. Adjust the AI Creativity
        Level to match your brand’s voice and tone. Our AI will generate at least six different
        variants and track their performance through extensive A/B testing. It will continue to
        create new versions until it identifies the most effective one, ensuring the content not
        only resonates with your audience but also reinforces your brand identity.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        height: 280,
        position: "relative",
        background: "#f5f5f5",
      }}
    >
      <div
        style={{
          width: '100%',
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f5f5f5",
        }}
      />
      <img
         src="https://login.seatext.com/ctas-buttons.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.CTAsOptimizationEnabled}
          onChange={(checked) => handleSettingChange('CTAsOptimizationEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Calls-to-Action (CTAs) and Buttons
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 76,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        AI rewrites calls to action and buttons based on your website's content and industry. This
        feature enables you to A/B test multiple options, helping you determine the most effective
        CTAs to convert your target audience and buyer personas.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        height: 280,
        position: "relative",
        background: "#fff",
      }}
    >
      <div
        style={{
          width: '100%',
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#fff",
        }}
      />
      <div style={{ width: 354, height: 224, position: "absolute", left: 28, top: 28 }}>
        <img
           src="https://login.seatext.com/menu-items.png"
          style={{
            width: 354,
            height: 224,
            position: "absolute",
            left: "-0.5px",
            top: "-0.5px",
            objectFit: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: 21,
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.MenuItemsEnhancementEnabled}
          onChange={(checked) => handleSettingChange('MenuItemsEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 442,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Menu items
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 442,
          top: 56,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Utilize AI to refine your menu item descriptions, making them more engaging and clear. Due
        to the concise nature of menu texts, it is essential to prioritize accuracy. We strongly
        recommend monitoring the suggested variants in the Variant Editor. Supervision is crucial as
        this text experiment progresses to ensure the precision and appeal of each entry.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        height: 280,
        position: "relative",
        background: "#f5f5f5",
      }}
    >
      <div
        style={{
          width: '100%',
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f6f6f6",
        }}
      />
      <img
         src="https://login.seatext.com/customer-reviews.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.CustomerReviewsEnhancementEnabled}
          onChange={(checked) => handleSettingChange('CustomerReviewsEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Customer reviews
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 56,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Our AI technology refines customer reviews by improving language, correcting grammatical
        errors, and moderating tone. It enhances the readability and professionalism of the reviews
        while preserving the original sentiment. Please note that if you wish to remove negative
        reviews, this must be done manually.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        height: 280,
        position: "relative",
        background: "#fff",
      }}
    >
      <div
        style={{
          width: '100%',
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#fff",
        }}
      />
      <img
         src="https://login.seatext.com/license-agreement-documentation.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.LegalDocumentationEnhancementEnabled}
          onChange={(checked) => handleSettingChange('LegalDocumentationEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 438,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        License Agreements 
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 438,
          top: 76,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        AI can assist in reviewing and suggesting revisions to license agreements and other legal
        documents. However, due to the critical nature of these documents, we strongly advise
        against activating this feature without thorough oversight. If you decide to use this
        capability, it should be limited to preliminary drafts or non-essential documents. Ensure
        that all AI-generated modifications are meticulously reviewed to maintain legal accuracy and
        compliance.
      </p>
    </div>
    <div
      style={{
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        height: 280,
        position: "relative",
        background: "#f6f6f6",
      }}
    >
      <div
        style={{
          width: '100%',
          height: 280,
          position: "absolute",
          left: "-0.5px",
          top: "-0.5px",
          background: "#f6f6f6",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "absolute",
          left: 1010,
          top: 28,
          gap: 5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1,
          paddingBottom: 1,
          borderRadius: 16,
          background: "#1890ff",
        }}
      >
        <Switch
          checked={settings.TechnicalDocumentationEnhancementEnabled}
          onChange={(checked) => handleSettingChange('TechnicalDocumentationEnhancementEnabled', checked)}
           
           disabled={false}   checkedChildren="ON"
        unCheckedChildren="OFF"
          icon={false}
          loading={false}
          size="medium"
          text
        />
      </div>
      <p
        style={{
          width: 462,
          position: "absolute",
          left: 440,
          top: 28,
          fontSize: 22,
          textAlign: "left",
          color: "rgba(0,0,0,0.85)",
        }}
      >
        Technical Documentation
      </p>
      <p
        style={{
          width: 464,
          position: "absolute",
          left: 440,
          top: 58,
          fontSize: 16,
          textAlign: "left",
          color: "rgba(0,0,0,0.45)",
        }}
      >
        Our AI can assist in refining the presentation of technical information on your website. It
        carefully rephrases content to improve clarity and understanding, which is particularly
        beneficial for software companies and other tech-focused entities. This AI feature focuses
        on enhancing the wording without altering the original technical details. It also corrects
        grammatical errors and optimizes the text for better readability. By implementing A/B
        testing, we can determine which version of the technical description resonates best.
      </p>
      <img
         src="https://login.seatext.com/technical-documentation-1.png"
        style={{
          width: 354,
          height: 224,
          position: "absolute",
          left: "27.5px",
          top: "27.5px",
          objectFit: "cover",
        }}
      />
    </div>
  </div>
</div>


<div style={{ height: '30px' }}></div>
<div >

           {/* AI Creativity Level and its description */}
           <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>AI Creativity Level:</Text>
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  marks={{
                    1: 'Min', 
                    2: 'Low',
                    3: 'Medium',
                    4: 'High',
                    5: 'Max', 
                  }}
                  onChange={(value) => handleSettingChange('ABtestingCreativityLevel', value)}
                  value={settings.ABtestingCreativityLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how creative the AI should be. A higher setting means the AI will generate content that's significantly different from the original, while a lower setting limits creativity to minimal changes. 
                </Text>
              </Col>
            </Row>

            {/* Number of Variants to Create and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Number of Variants to Create:</Text>
                <Slider
                  min={2}
                  max={8}
                  step={1}
                  marks={{
                   
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingVariantsTnumber', value)}
                  value={settings.ABtestingVariantsTnumber}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Choose the number of variant outputs. A lower number is suitable for sites with small traffic, while a higher number caters to websites with at least 30K visitors a month.
                </Text>
              </Col>
            </Row>
  
            {/* Preserve Your Brand Voice and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '150px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Preserve Your Brand Voice (0-10):</Text>
                <Slider
                  min={0}
                  max={10}
                  step={1}
                  onChange={(value) => handleSettingChange('ABtestingBrandVoicePreservation', value)}
                  value={settings.ABtestingBrandVoicePreservation}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                    
                  }}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Should AI copy your Brand voice characteristics, keeping it closer to your brand style, or have the ability to be more creative and depart from usual brand patterns? A higher value preserves your brand voice more closely.
                </Text>
              </Col>
            </Row>
  
            {/* A/B Testing Settings */}
            {/* Confidence Level for A/B Testing and its description */}
            <Title level={2} align="center" style={{ marginBottom: '50px' }}>A/B Testing Settings</Title>
  
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}>
              <Col span={12}>
                <Text>Confidence Level for A/B Testing:</Text>
                <Slider
                  min={70}
                  max={95}
                  step={5}
                  marks={{
                    70: '70%',
                    75: '75%',
                    80: '80%',
                    85: '85% Recommended',
                    90: '90%',
                    95: '95%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingConfidenceLevel', value)}
                  value={settings.ABtestingConfidenceLevel}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  This slider adjusts the confidence level required to determine a winning variant in A/B testing. A higher confidence level means requiring more evidence (data) before making a decision, reducing the risk of false positives but potentially requiring more time and traffic.
                </Text>
              </Col>
            </Row>
  
            {/* Early Elimination of Underperforming Variants and its description */}
            <Row gutter={[16, 24]} style={{ marginBottom: '20px', alignItems: 'top' }}> 
              <Col span={12}>
                <Text>Early Elimination of Underperforming Variants:</Text>
                <Slider
                  min={60}
                  max={70}
                  step={2}
                  marks={{
                    60: '60%',
                    62: '62%',
                    65: '65% Recommended',
                    67: '67%',
                    70: '70%',
                  }}
                  onChange={(value) => handleSettingChange('ABtestingEarlyElimination', value)}
                  value={settings.ABtestingEarlyElimination}
                  tooltipVisible={false}
                />
              </Col>
              <Col span={12} style={{ paddingLeft: '50px' }}>
                <Text>
                  Adjust how quickly underperforming variants are discarded during A/B testing. A lower threshold is less aggressive, keeping variants longer for more data collection, while a higher threshold more quickly eliminates variants deemed underperforming based on early results.
                </Text>
              </Col>
            </Row>
  

            <Row gutter={[16, 24]} style={{ marginBottom: '50px', alignItems: 'top' }}> {/* Increased vertical space for the last slider */}
                    <Col span={12}>
                        <Text>Introduce AI variants slowly to your visitors:</Text>
                        <Slider
                            min={1}
                            max={6}
                            step={1}
                            marks={{
                                1: '100%',
                                2: '80%',
                                3: '60%',
                                4: '40%',
                                5: '10%',
                                6: '0% ',
                            }}
                            defaultValue={1}
                            onChange={(value) => handleSettingChange('ABtestingTrafficPercentageForVariants', value)}
                           tooltipVisible={false}
                        />
                    </Col>
                    <Col span={12} style={{ paddingLeft: '50px' }}>
                        <Text >
                            This percantage of traffic should see AI variants, put it to 10% if you need to introduce AI variants to your visitors very slowly.  Your original text wil be tested in A/B mix in any case as one of variant.
                        </Text>
                    </Col>
                </Row>



                </div>
          </Card>
          
        </div>
      </div>
    </div>
    </div>
  );
  
};

export default SettingsPage2;