import React, { useState, useEffect } from 'react';
import { Card, Typography, Row, Col, Spin } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { fetchImprovements } from './utils';

const { Text } = Typography;

// Color constants
const COLORS = {
  darkBlue: '#0047AB',  // A rich dark blue
  lightBlue: '#E6F4FF', // Very light blue background
  accentBlue: '#1890FF' // Medium blue for secondary elements
};

const ImprovementsList = ({ url, language }) => {
  const [improvements, setImprovements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    const getImprovements = async () => {
      if (!url || !language) {
        setLoading(false);
        return;
      }

      try {
        const data = await fetchImprovements(url, language);
        if (isMounted) {
          if (data && Array.isArray(data.improvements)) {
            setImprovements(data.improvements);
          } else {
            setImprovements([]);
          }
        }
      } catch (error) {
        console.error('Error fetching improvements:', error);
        if (isMounted) {
          setImprovements([]);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    getImprovements();

    return () => {
      isMounted = false;
    };
  }, [url, language]);

  const renderImprovementCard = (improvement, index) => {
    const conversionIncrease = (((improvement.bestVariantRate - improvement.originalRate) / 20) * 100).toFixed(2);
    const originalScore = ((improvement.originalRate / 20) * 100).toFixed(2);
    const improvedScore = ((improvement.bestVariantRate / 20) * 100).toFixed(2);
    const isShortText = improvement.originalText.length < 120;

    return (
      <Col 
        key={`${improvement.originalText}-${index}`}
        span={isShortText ? 12 : 24}
        style={{ padding: '8px' }}
      >
        <Card 
          hoverable
          className="improvement-card"
          style={{ 
            height: '100%',
            border: '1px solid #f0f0f0',
            borderRadius: '12px',
            overflow: 'hidden',
            transition: 'all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <div style={{ display: 'flex', gap: 20, alignItems: 'flex-start' }}>
            {/* Conversion Increase */}
            <div style={{
              background: COLORS.lightBlue,
              padding: '16px',
              borderRadius: '12px',
              minWidth: '160px',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                marginBottom: '4px'
              }}>
                <ArrowUpOutlined style={{ 
                  color: COLORS.darkBlue,
                  fontSize: 24 
                }} />
                <Text style={{ 
                  fontSize: 32,
                  fontWeight: 'bold',
                  color: COLORS.darkBlue,
                  margin: 0,
                  lineHeight: 1
                }}>
                  +{conversionIncrease}%
                </Text>
              </div>
              <Text style={{
                fontSize: 13,
                color: COLORS.darkBlue,
                display: 'block',
                marginTop: '4px'
              }}>
                conversion score increase
              </Text>
            </div>

            {/* Text Content */}
            <div style={{ flex: 1 }}>
              {/* Original Text */}
              <div style={{ marginBottom: 20 }}>
                <Text style={{ 
                  fontSize: 12,
                  color: '#8c8c8c',
                  display: 'block',
                  marginBottom: '6px'
                }}>
                  {originalScore}% conversion score of original text:
                </Text>
                <Text style={{
                  fontSize: 16,
                  color: '#000',
                  display: 'block',
                  lineHeight: 1.5
                }}>
                  {improvement.originalText}
                </Text>
              </div>

              {/* Improved Text */}
              <div>
                <Text style={{ 
                  fontSize: 12,
                  color: '#8c8c8c',
                  display: 'block',
                  marginBottom: '6px'
                }}>
                  {improvedScore}% conversion score of AI improved variant:
                </Text>
                <Text style={{ 
                  fontSize: 16,
                  color: COLORS.darkBlue,
                  fontWeight: 'bold',
                  display: 'block',
                  lineHeight: 1.5
                }}>
                  {improvement.bestVariantText}
                </Text>
              </div>
            </div>
          </div>
        </Card>

        <style jsx global>{`
          .improvement-card {
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
            will-change: transform;
          }
          
          .improvement-card:hover {
            transform: scale(1.05) translateY(-10px);
            box-shadow: 0 20px 40px rgba(0, 71, 171, 0.2);
            z-index: 10;
          }
          
          .improvement-card .ant-card-body {
            padding: 24px;
          }
          
          /* Add overlay to emphasize the card when hovering */
          .improvement-card:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 71, 171, 0);
            transition: background 0.4s ease;
            pointer-events: none;
          }
          
          .improvement-card:hover:after {
            background: rgba(0, 71, 171, 0.02);
          }
        `}</style>
      </Col>
    );
  };

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {loading ? (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          padding: '40px' 
        }}>
          <Spin size="large" />
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          {improvements.map((improvement, index) => 
            renderImprovementCard(improvement, index)
          )}
        </Row>
      )}
    </div>
  );
};

export { ImprovementsList };